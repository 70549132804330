import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type TrafficIncidentsInfoBoxClassKey =
    | 'age'
    | 'delimiter'
    | 'description'
    | 'firstRow'
    | 'icon'
    | 'incidentInfo'
    | 'infoIcon'
    | 'listItem'
    | 'listItemText'
    | 'root'
    | 'titleAndTimestamp';

export const TrafficIncidentsInfoBoxStyleRules: StyleRulesCallback<Theme, {}, TrafficIncidentsInfoBoxClassKey> = (
    theme: Theme
) => {
    return createStyles({
        age: {
            opacity: 0.8,
        },
        delimiter: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        description: {
            color: theme.palette.text.primary,
            lineHeight: '17px',
            width: '100%',
        },
        firstRow: {
            display: 'flex',
            marginBottom: theme.spacing(),
            width: '100%',
        },
        icon: {
            alignSelf: 'flex-start',
            height: 32,
            marginRight: theme.spacing(2),
            width: 32,
        },
        incidentInfo: {
            alignItems: 'center',
            display: 'flex',
            lineHeight: '14px',
            marginTop: theme.spacing(0.5),
        },
        infoIcon: {
            fontSize: 14,
            marginRight: theme.spacing(0.5),
        },
        listItem: {
            flexDirection: 'column',
            paddingBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1.5),
        },
        listItemText: {
            marginBottom: 0,
            marginTop: 0,
        },
        root: {
            paddingBottom: 0,
            paddingTop: 0,
        },
        titleAndTimestamp: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });
};
