import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type OptionsInputClassKey = 'disabled' | 'notchedOutline' | 'root';

export const OptionsInputStyleRules: StyleRulesCallback<{}, {}, OptionsInputClassKey> = (theme: Theme) =>
    createStyles({
        disabled: {},
        notchedOutline: {
            border: `1px solid ${theme.palette.warning.main}`,
        },
        root: {
            '&:hover::not($focused):not($error) $notchedOutline': {
                borderColor: `${theme.palette.warning.dark} !important`,
            },
            '&$disabled $notchedOutline': {
                borderColor: theme.palette.warning.main,
            },
        },
    });
