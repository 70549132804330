import type { Driver, Vehicle } from '~/common';
import { getValueFromHash } from '~/common';
import { addDates, durationToString, format, startOfDay, stringToDuration, sumDurations } from '~/libs/dates';
import type { NumericDictionary } from '~/libs/utility';
import { uuidv4 } from '~/libs/utility';
import type { AssetType } from '~/services/ApiClient';
import { themes } from '~/theme';

import { ACTIVITY_TYPES } from './consts';
import type {
    QueryAssetActivitiesReportByIdsReadGraphResolved,
    QueryDriverActivitiesReportByIdsRead,
    QueryDriverActivitiesReportByIdsReadResolved,
    QueryDriverActivitiesReportByIdsReadResolvedBase,
} from './models';

interface DriverActivitiesResolverArgs {
    drivers: NumericDictionary<Driver>;
    source: QueryDriverActivitiesReportByIdsRead[];
    vehicles: NumericDictionary<Vehicle>;
}

const driverActivitiesResolver = (args: DriverActivitiesResolverArgs) => {
    const { drivers, source, vehicles } = args;

    return source.map(
        ({ driver, vehicle, ...rest }): QueryDriverActivitiesReportByIdsReadResolvedBase => ({
            ...rest,
            ...(driver && { driver: getValueFromHash(drivers, driver.id) }),
            ...(vehicle && { vehicle: getValueFromHash(vehicles, vehicle.id) }),
            id: uuidv4(),
            subtotal: durationToString(sumDurations(rest.drive ?? 'P0Y', rest.available ?? 'P0Y', rest.work ?? 'P0Y')),
            total: durationToString(
                sumDurations(
                    rest.drive ?? 'P0Y',
                    rest.available ?? 'P0Y',
                    rest.work ?? 'P0Y',
                    rest.rest ?? 'P0Y',
                    rest.nightRest ?? 'P0Y'
                )
            ),
        })
    );
};

const driverActivitiesListResolver = (args: DriverActivitiesResolverArgs) => {
    const { drivers, source, vehicles } = args;

    const resolvedItem = driverActivitiesResolver({ drivers, source, vehicles });

    return resolvedItem.map(
        ({ date, ...rest }): QueryDriverActivitiesReportByIdsReadResolved => ({
            ...rest,
            date: startOfDay(date),
            startTime: format(date, 'HH:mm:ss'),
        })
    );
};

const getAxis = (item: Partial<QueryDriverActivitiesReportByIdsRead>) => {
    const { available, drive, nightRest, rest, work } = item;

    return {
        xAxis:
            ((nightRest || rest) && ACTIVITY_TYPES.REST) ||
            (work && ACTIVITY_TYPES.WORK) ||
            (drive && ACTIVITY_TYPES.DRIVE) ||
            ((available && ACTIVITY_TYPES.AVAILABLE) as string),
        yAxis: nightRest || rest || work || drive || available || 'P0Y',
    };
};

const driverActivitiesGraphResolver = (args: DriverActivitiesResolverArgs, assetType: AssetType) => {
    const { drivers, source, vehicles } = args;

    // Resolve driver activities
    const resolvedActivities = driverActivitiesResolver({ drivers, source, vehicles });

    const result = {}; // Use a plain object to accumulate results

    for (const value of resolvedActivities) {
        const propertyValue = value[assetType];

        if (!propertyValue) {
            // Skip entries without the specified propertyKey
            continue;
        }

        // Precomputed frequently used values
        const dateFormatted = format(value.date, 'P');
        const key = `${dateFormatted}-${propertyValue.id}`;
        const { xAxis, yAxis } = getAxis(value);
        const duration = stringToDuration(yAxis);

        // Check if the graph entry already exists, if not, initialize it
        if (!result[key]) {
            result[key] = {
                date: value.date,
                driver: value.driver,
                id: value.id,
                series: [],
                subtotal: 'P0Y',
                total: 'P0Y',
                vehicle: value.vehicle,
            };
        }

        const graphEntry = result[key];

        // Cache result of sumDurations to avoid multiple function calls
        const updatedXAxisDuration = sumDurations(graphEntry[xAxis] || 'P0Y', duration);
        const updatedSubtotalDuration = sumDurations(graphEntry.subtotal, value.subtotal);
        const updatedTotalDuration = sumDurations(graphEntry.total, value.total);

        // Update graph entry properties
        graphEntry[xAxis] = durationToString(updatedXAxisDuration);
        graphEntry.subtotal = durationToString(updatedSubtotalDuration);
        graphEntry.total = durationToString(updatedTotalDuration);

        // Push series data
        graphEntry.series.push({
            fillColor: xAxis && themes.default.functionalItemsColors.driverActivity[xAxis]?.value,
            meta: {
                address: value.location?.address,
                date: value.date,
                distance: value.distance,
                driver: value.driver,
                duration: yAxis,
                vehicle: value.vehicle,
            },
            x: xAxis,
            y: [value.date.getTime(), addDates(value.date, duration).getTime()],
        });
    }

    // Convert accumulated results to an array
    return Object.values<QueryAssetActivitiesReportByIdsReadGraphResolved>(result);
};

export { driverActivitiesGraphResolver, driverActivitiesListResolver, getAxis };
