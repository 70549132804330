import type { ComponentEnhancer } from 'react-recompose';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import type { Subtract } from 'utility-types';

import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { floorCurrentTimestampStateSelector } from '~/components/SynchronizationClock';
import { keyDataStateSelectorFactory } from '~/components/UserPreferences';
import { featureFlagsSelector, languageStateSelector } from '~/selectors';
import { PredefinedUserPreferencesKeys } from '~/services/PredefinedUserPreferences';

import type { DisplayUserPreferences } from '../DisplayPreferences';

import { updateLayersIntervalMilliseconds } from './constants';
import { MapProfile, mapProfileSelector } from './mapProfile';
import { MAP_USERPREFERENCES_KEY } from './preferences';
import type { MapDispatchProps, MapReduxProps, MapStateProps } from './redux';
import { mapDispatchToProps, mapStateToProps, mergeProps } from './redux';

export { useMapLayers } from './hooks';
export { getBaseMapLayersProps } from './layers.base';
export { PtvLayer } from './layers.ptv';
export { MapProfile } from './mapProfile';
export { MapViewMode } from './mapViewMode';
export { defaultMapUserPreferences, MAP_USERPREFERENCES_KEY, requiredMapUserPreferences } from './preferences';

export interface InjectedMapSharedProps extends MapReduxProps {}

export type { MapUserPreferences } from './preferences';

export const withMapSharedProps = <WrappedProps extends InjectedMapSharedProps>(
    mapProfile: MapProfile = MapProfile.PTV_DEFAULT
): ComponentEnhancer<WrappedProps, Subtract<WrappedProps, InjectedMapSharedProps>> =>
    compose<WrappedProps, Subtract<WrappedProps, InjectedMapSharedProps>>(
        connect<MapStateProps, MapDispatchProps>(
            createSelector(
                floorCurrentTimestampStateSelector(updateLayersIntervalMilliseconds),
                keyDataStateSelectorFactory(MAP_USERPREFERENCES_KEY),
                settingDataSelector(SettingsKey.SECURABLES),
                languageStateSelector,
                keyDataStateSelectorFactory<DisplayUserPreferences>(PredefinedUserPreferencesKeys.DISPLAY),
                mapProfileSelector(mapProfile),
                featureFlagsSelector,
                mapStateToProps
            ),
            mapDispatchToProps,
            mergeProps
        )
    );
