import { StyledPopup } from '@fv/components';
import classNames from 'classnames';
import type { LeafletEvent, FeatureGroup as LeafletFeatureGroup, LeafletMouseEvent } from 'leaflet';
import type { FC } from 'react';
import { FeatureGroup, GeoJSON } from 'react-leaflet';

import { MovementPositionGroup } from '~/components/MovementPositionGroup';

import * as utility from '../../utility';

import type { PositionGroupRouteInnerProps } from './models';

let leafletTimeout: number;
let leafletEvents: LeafletEvent[] = [];

const PositionGroupRoute: FC<PositionGroupRouteInnerProps> = ({
    AssetIcon,
    classes,
    dataId,
    disabled,
    highlightPositionGroup,
    positionGroup,
    scrollToPositionGroup,
}) => {
    const cancelOldHidePopup = () => {
        clearTimeout(leafletTimeout);
    };
    const handleMouseOver = (evt: LeafletMouseEvent) => {
        cancelOldHidePopup();
        leafletEvents.push(evt);
        leafletTimeout = window.setTimeout(() => {
            (evt.target as LeafletFeatureGroup).openPopup(evt.latlng);
        }, 500);
        highlightPositionGroup(positionGroup);
    };
    const hidePopup = () => {
        if (leafletEvents.length) {
            leafletEvents.forEach((evt) => {
                (evt.target as LeafletFeatureGroup).closePopup();
            });
            leafletEvents = [];
        }
    };
    const handleMouseOut = () => {
        cancelOldHidePopup();
        leafletTimeout = window.setTimeout(hidePopup, 500);
    };
    const scheduleNewHidePopup = () => {
        cancelOldHidePopup();
        leafletTimeout = window.setTimeout(hidePopup, 1000);
    };

    const coordinates = utility.getAggregatedCoordinates(
        [positionGroup],
        utility.LatLngOrder.LngLat
    ) as GeoJSON.LineString['coordinates'];
    if (coordinates.length === 0) {
        return null;
    }

    const geoJsonObject: GeoJSON.LineString = {
        coordinates,
        type: 'LineString',
    };

    const lineClassName = classNames(classes.movementLine, dataId);

    const lineBorderClass = classNames(classes.movementLineBorder, {
        [classes.movementLineDisabled]: disabled,
    });

    // As the className is not a dynamic property, need to update the key to for the new classes to be applied
    const lineKey = disabled ? 'line:disabled' : 'line';

    return (
        <FeatureGroup
            eventHandlers={{ click: scrollToPositionGroup, mouseout: handleMouseOut, mouseover: handleMouseOver }}
            key={lineKey}
        >
            <GeoJSON data={geoJsonObject} key="border" style={{ className: lineBorderClass }} />
            <GeoJSON data={geoJsonObject} key="line" style={{ className: lineClassName }} />;
            <div onMouseEnter={cancelOldHidePopup} onMouseLeave={scheduleNewHidePopup}>
                <StyledPopup className={classes.movementPopup}>
                    <div className={classes.box} data-id={`route-popup:${positionGroup.id}`}>
                        <MovementPositionGroup AssetIcon={AssetIcon} popup positionGroup={positionGroup} />
                    </div>
                </StyledPopup>
            </div>
        </FeatureGroup>
    );
};

PositionGroupRoute.displayName = 'PositionGroupRoute';
export default PositionGroupRoute;
