import type { Theme } from '@mui/material';
import type { StyleRulesCallback } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

export type PositionGroupRouteClassKey =
    | 'box'
    | 'movementLine'
    | 'movementLineBorder'
    | 'movementLineDisabled'
    | 'movementPopup';

export const PositionGroupRouteStyleRules: StyleRulesCallback<Theme, {}, PositionGroupRouteClassKey> = (theme: Theme) =>
    createStyles({
        box: {
            '& > :first-child': {
                display: 'block',
            },
            '& > p > :last-child': {
                marginLeft: '20px',
            },
            padding: '0 10px',
        },

        movementLine: {
            opacity: 0.38,
            stroke: theme.palette.common.white,
            strokeWidth: 5,
        },

        movementLineBorder: {
            strokeWidth: 7,
        },

        movementLineDisabled: {
            opacity: 0.38,
        },
        movementPopup: {
            '@global .leaflet-popup-tip-container ': {
                left: '50%',
            },
            left: '-75px !important',
        },
    });
