import type { Dispatch } from 'redux';
import type { Subtract } from 'utility-types';

import { MapViewMode } from '~/components/MapSharedProps';

import { showAllSelectedAssetsAction } from '../../../../data';

import type { DispatchProps, MapActionsInnerProps } from './component';

export const mapDispatchToProps = (
    dispatch: Dispatch,
    { changeMapViewMode, mapViewMode }: Subtract<MapActionsInnerProps, DispatchProps>
): DispatchProps => {
    return {
        changeViewModeToHybrid: () => {
            if (mapViewMode === MapViewMode.HYBRID) {
                return;
            }
            changeMapViewMode(MapViewMode.HYBRID);
        },
        changeViewModeToMap: () => {
            if (mapViewMode === MapViewMode.MAP) {
                return;
            }
            changeMapViewMode(MapViewMode.MAP);
        },
        changeViewModeToSatellite: () => {
            if (mapViewMode === MapViewMode.SATELLITE) {
                return;
            }
            changeMapViewMode(MapViewMode.SATELLITE);
        },
        showAllSelectedAssets: () => {
            dispatch(showAllSelectedAssetsAction());
        },
    };
};
