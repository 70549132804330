import type { ReactNode } from 'react';
import { Component } from 'react';
import { generatePath } from 'react-router';

import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import type { MonitoringTrailerEntry, MonitoringViewMode } from '~/data/monitoring';
import { MonitoringPerspective } from '~/data/monitoring';

import { MONITORING_PATH_STRUCTURE } from '../../../../consts';

import { TrailerHeader } from './components/TrailerHeader';
import type { SectionName } from './constants';
import type { TrailerDetailsPaneInnerProps } from './model';
import type { TrailerDetailsPaneUserPreferences } from './preferences';
import { DETAILSPANE_TRAILER_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized, unauthorizedSectionsNamesMemoized } from './sections';

const DetailsPaneComponent = SceneDetailsPaneFactory<
    MonitoringTrailerEntry,
    TrailerDetailsPaneUserPreferences,
    SectionName
>(DETAILSPANE_TRAILER_USERPREFERENCES_KEY);

export class TrailerDetailsPaneComponent extends Component<TrailerDetailsPaneInnerProps> {
    private getSections = (
        userPreferences: TrailerDetailsPaneUserPreferences,
        toggleCollapsedState: (sectionName: SectionName) => () => void,
        monitoringViewMode: MonitoringViewMode
    ) =>
        getSectionsMemoized({
            changeCollapsedCompartments: this.props.changeCollapsedCompartments,
            changeMiniMapZoomLevel: this.props.changeMiniMapZoomLevel,
            collapsedCompartments: userPreferences.collapsedCompartments,
            collapsedSections: userPreferences.collapsedSections,
            entry: this.props.entry,
            handleLocate: this.props.panToTrailer,
            miniMapZoomLevel: userPreferences.miniMapZoomLevel,
            toggleCollapsedState,
            viewMode: monitoringViewMode,
        });

    private handleClose = () => {
        this.props.history.push(
            generatePath(MONITORING_PATH_STRUCTURE, {
                perspective: MonitoringPerspective.TRAILER,
                viewMode: this.props.viewMode,
            })
        );
    };

    public render(): ReactNode {
        const { canViewMap, entry, viewMode } = this.props;

        return (
            <DetailsPaneComponent
                dataId="trailer-details-pane"
                entry={entry}
                getSections={(userPreferences, toggleCollapsedState) =>
                    this.getSections(userPreferences, toggleCollapsedState, viewMode)
                }
                Header={TrailerHeader}
                onClose={this.handleClose}
                unauthorizedSections={unauthorizedSectionsNamesMemoized(canViewMap)}
            />
        );
    }
}
