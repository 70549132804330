/* eslint-disable global-require */
import slide82 from './slide-82.png';
import slide83 from './slide-83.png';
import slide84 from './slide-84.gif';
import slide85 from './slide-85.png';

export const carousel = {
    assets: { 'slide-82': slide82, 'slide-83': slide83, 'slide-84': slide84, 'slide-85': slide85 },
    autoPlaySpeed: 18000,
    customUrl: { 'slide-76': 'https://share.hsforms.com/1Q5nZGDbQSRqrD6qFwDRlnQ2irkd' },
    fallback: require('./carousel.en-GB.json'),
    languages: {
        'de-DE': require('./carousel.de-DE.json'),
        'es-ES': require('./carousel.es-ES.json'),
        'fr-FR': require('./carousel.fr-FR.json'),
        'it-IT': require('./carousel.it-IT.json'),
        'nl-NL': require('./carousel.nl-NL.json'),
        'pl-PL': require('./carousel.pl-PL.json'),
    },
    revision: 30,
};
