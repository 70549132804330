import type * as i18next from 'i18next';
import * as React from 'react';

import { LocationSection, PositionMiniMapSection } from '~/common';
import type { SectionDefinition } from '~/components/Sections';
import { ReorderableSection } from '~/components/Sections';
import type { ResolvedAttachment, ResolvedMessage } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { MessageDetailsSection } from '../MessageDetailsSection';
import { MetadataSection } from '../MetadataSection';
import { WorkflowBodyContent } from '../WorkflowBodyContent';

import { AttachmentsSectionContent } from './components/AttachmentsSectionContent';
import { SectionName } from './constants';

export const getSections = (
    collapsedSections: SectionName[],
    toggleCollapsedState: (sectionName: SectionName) => () => void,
    t: i18next.TFunction,
    message: ResolvedMessage,
    messageResolvedAttachments: ResolvedAttachment[],
    attachmentRef: React.Ref<HTMLDivElement>,
    showEmptyFields: boolean,
    miniMapZoomLevel: number,
    changeMiniMapZoomLevel: (zoomLevel: number) => void
): SectionDefinition[] => {
    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <MessageDetailsSection
                    dataId={SectionName.MESSAGEINFO}
                    dragHandleElement={dragHandleElement}
                    headerOverContent
                    hideCollapsing
                    isCollapsed={false}
                    message={message}
                    title={t('message-info')}
                    toggleCollapsed={toggleCollapsedState(SectionName.MESSAGEINFO)}
                />
            ),
            name: SectionName.MESSAGEINFO,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <PositionMiniMapSection
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    position={message.value.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    address={message.value.location?.address}
                    courseOverGround={message.value.courseOverGround}
                    datetime={message.value.location?.dateTime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    odometer={message.value.odometer}
                    position={message.value.location?.position}
                    speedOverGround={message.value.speedOverGround}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ReorderableSection
                    dataId={SectionName.WORKFLOWBODY}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.WORKFLOWBODY)}
                    title={t('form-details')}
                    toggleCollapsed={toggleCollapsedState(SectionName.WORKFLOWBODY)}
                >
                    <WorkflowBodyContent messageBody={message.body} showEmptyFields={showEmptyFields} />
                </ReorderableSection>
            ),
            name: SectionName.WORKFLOWBODY,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ReorderableSection
                    dataId={SectionName.ATTACHMENTS}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.ATTACHMENTS)}
                    ref={attachmentRef}
                    title={t('attachments')}
                    toggleCollapsed={toggleCollapsedState(SectionName.ATTACHMENTS)}
                >
                    <AttachmentsSectionContent attachments={messageResolvedAttachments} message={message} />
                </ReorderableSection>
            ),
            name: SectionName.ATTACHMENTS,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <MetadataSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.METADATA)}
                    message={message}
                    toggleCollapsed={toggleCollapsedState(SectionName.METADATA)}
                />
            ),
            name: SectionName.METADATA,
        },
    ];
};

export const getSectionsMemoized = memoizeOne(getSections);
