import type { Address, Driver, GenericState, Vehicle } from '~/common';
import type {
    AssetType,
    DeepPojoModel,
    QueryDriverActivitiesReportByIdsApi,
    QueryDriverActivitiesReportByIdsReadApi,
    QueryDriverActivitiesReportByIdsResponseApi,
    ServerResultStatus,
} from '~/services/ApiClient';
import type { BaseAction, FulfilledMeta, Pending, Rejected } from '~/types';

import type { ACTION_TYPE_KEYS } from './actionsTypes';
import type { ACTIVITY_TYPES } from './consts';

export enum ViewMode {
    GRAPH = 'graph',
    LIST = 'list',
}

type QueryDriverActivitiesReportByIds = {
    viewMode: ViewMode;
} & DeepPojoModel<QueryDriverActivitiesReportByIdsApi>;
type QueryDriverActivitiesReportByIdsResponse = DeepPojoModel<QueryDriverActivitiesReportByIdsResponseApi>;
type QueryDriverActivitiesReportByIdsRead = DeepPojoModel<QueryDriverActivitiesReportByIdsReadApi>;

interface QueryDriverActivitiesReportByIdsReadResolvedBase
    extends Omit<QueryDriverActivitiesReportByIdsRead, 'driver' | 'vehicle'> {
    driver?: Driver;
    id: string;
    subtotal: string;
    total: string;
    vehicle?: Vehicle;
}
interface QueryDriverActivitiesReportByIdsReadResolved extends QueryDriverActivitiesReportByIdsReadResolvedBase {
    startTime: string;
}

interface DriverActivityReportGraphSeries {
    fillColor?: string;
    meta: {
        address?: Address;
        date: Date;
        distance?: number;
        driver?: Driver;
        duration: string;
        vehicle?: Vehicle;
    };
    x: string;
    y: number[];
}
interface QueryAssetActivitiesReportByIdsReadGraphResolved
    extends Omit<QueryDriverActivitiesReportByIdsReadResolvedBase, 'nightRest'> {
    series: DriverActivityReportGraphSeries[];
}

type DriverActivitiesReportSceneState = GenericState<
    QueryAssetActivitiesReportByIdsReadGraphResolved | QueryDriverActivitiesReportByIdsReadResolved
>;

type ActivityTypes = (typeof ACTIVITY_TYPES)[keyof typeof ACTIVITY_TYPES];

export interface ActionTypeMeta {
    assetType: AssetType;
    viewMode: ViewMode;
}

type ActionTypes =
    | BaseAction<typeof ACTION_TYPE_KEYS.RESET>
    | FulfilledMeta<typeof ACTION_TYPE_KEYS.LIST_FULFILLED, QueryDriverActivitiesReportByIdsRead[], ActionTypeMeta>
    | Pending<typeof ACTION_TYPE_KEYS.LIST_PENDING>
    | Rejected<typeof ACTION_TYPE_KEYS.LIST_REJECTED, ServerResultStatus.BADREQUEST | ServerResultStatus.SERVERERROR>;

export type {
    ActionTypes,
    ActivityTypes,
    DriverActivitiesReportSceneState,
    DriverActivityReportGraphSeries,
    QueryAssetActivitiesReportByIdsReadGraphResolved,
    QueryDriverActivitiesReportByIds,
    QueryDriverActivitiesReportByIdsRead,
    QueryDriverActivitiesReportByIdsReadResolved,
    QueryDriverActivitiesReportByIdsReadResolvedBase,
    QueryDriverActivitiesReportByIdsResponse,
};
