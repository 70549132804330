import type { Dictionary } from '../../../utility';
import { isEmpty } from '../../../utility';

import type { FeatureDetails } from './components/Xserver';
import type { FeaturesPopupState } from './models';

const getFeaturesPopupState = (features: Dictionary<FeatureDetails>): FeaturesPopupState | undefined => {
    const interactiveFeatures = Object.keys(features || [])
        .map((key) => features[key])
        .filter((feature) => feature.themeId === 'PTV_TrafficIncidents');

    return isEmpty(interactiveFeatures)
        ? undefined
        : {
              identifier: interactiveFeatures[0].id,
              items: interactiveFeatures,
              position: interactiveFeatures[0].latLng,
          };
};

export { getFeaturesPopupState };
