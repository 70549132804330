import type { Dispatch } from 'redux';

import { panToAssetAction } from '~/components/SceneMap';
import { updateUserPreferencesAction } from '~/data/userpreferences';
import type { Securables } from '~/services/ApiClient';

import type { DispatchProps, StateProps } from './model';
import { DETAILSPANE_TRAILER_USERPREFERENCES_KEY } from './preferences';

export const mapStateToProps = (securables: Securables): StateProps => ({ canViewMap: securables.monitoring.map });

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    changeCollapsedCompartments: (collapsedCompartments: number[]) => {
        dispatch(updateUserPreferencesAction(DETAILSPANE_TRAILER_USERPREFERENCES_KEY, { collapsedCompartments }));
    },
    changeMiniMapZoomLevel: (zoomLevel: number) => {
        dispatch(updateUserPreferencesAction(DETAILSPANE_TRAILER_USERPREFERENCES_KEY, { miniMapZoomLevel: zoomLevel }));
    },
    panToTrailer: () => {
        dispatch(
            panToAssetAction(
                'mapping',
                'locate-from-trailer-details',
                'Locate on map from trailer details',
                'location-section'
            )
        );
    },
});
