import { defaultUserPreferences } from '~/common';
import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';

import type { SectionName } from './consts';
import { MONITORING_USERPREFERENCES_KEY } from './consts';

export const requiredMonitoringUserPreferences = {
    defaultState: defaultUserPreferences,
    key: MONITORING_USERPREFERENCES_KEY,
};

export interface VehicleDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    collapsedTemperatureCompartments: number[];
    miniMapZoomLevel: number;
}

export const DRIVERSTATUS_DETAILSPANE_USERPREFERENCES_KEY = 'driver-status-detailspane-state';
