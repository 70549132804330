import type { GroupKey, GroupSummaryItem, Grouping, SummaryItem } from '@devexpress/dx-react-grid';
import {
    DataTypeProvider,
    FilteringState,
    GroupingState,
    IntegratedFiltering,
    IntegratedGrouping,
    IntegratedSelection,
    IntegratedSorting,
    IntegratedSummary,
    RowDetailState,
    SelectionState,
    SortingState,
    SummaryState,
} from '@devexpress/dx-react-grid';
import type { Table } from '@devexpress/dx-react-grid-material-ui';
import {
    DragDropProvider,
    Grid,
    GroupingPanel,
    TableColumnReordering,
    TableColumnResizing,
    TableColumnVisibility,
    TableFilterRow,
    TableFixedColumns,
    TableGroupRow,
    TableHeaderRow,
    TableRowDetail,
    TableSelection,
    TableSummaryRow,
    Toolbar,
    VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import type { FC } from 'react';
import { memo, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty, isUndefined, without } from '~/libs/utility';
import { getConfig } from '~/services/Config';
import { memoizeOne } from '~/services/Memoize';

import {
    EmptyMessage,
    ExpandCollapseControls,
    GroupingPaneContainer,
    HeaderRowCell,
    NoDataCell,
    SortLabel,
    TableFixedColumnsCell,
    ToolbarRoot,
    VirtualTableCell,
    createTableGroupRowContent,
    createTableHeaderRowTitle,
} from './components';
import { FilterCellFactory } from './components/FilterCell';
import { FilterIcon } from './components/FilterIcon';
import { GridRoot } from './components/GridRoot';
import { InlineSummary } from './components/InlineSummary';
import { TableRow } from './components/TableRow';
import { TotalCounterFooter } from './components/TotalCounterFooter';
import { rowHeight } from './constants';
import { createFilteringColumnExtensions } from './createFilteringColumnExtensions';
import { createGroupedGridGetRowId } from './groupedGridGetRowId';
import type { GridColumnDefinition, GridInnerProps, GridRowId, GroupingStateProps } from './models';
import { GridFilterOperation } from './models';
import { TableGroupRowCell, TableGroupRowSummaryCell, minColumnWidth } from './styles';

export enum GridSortingDirection {
    ASC = 'asc',
    DESC = 'desc',
}

const createRootGrid = (gridDataId?: string): React.FC<Grid.RootProps> => {
    return (props) => <GridRoot {...props} dataId={gridDataId} />;
};

const createTableFixedColumnsCell = (selectedRowId?: GridRowId, highlightedRowId?: GridRowId) => {
    return (props: TableFixedColumns.CellProps) => {
        const selected = !isUndefined(selectedRowId) && props.tableRow.rowId === selectedRowId;
        const highlighted = !isUndefined(highlightedRowId) && props.tableRow.rowId === highlightedRowId;
        return <TableFixedColumnsCell {...props} highlighted={highlighted} selected={selected} />;
    };
};

export const GridComponentFactory = <T extends object>(): React.FC<GridInnerProps<T>> => {
    const createTableHeaderRowTitleMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) =>
        createTableHeaderRowTitle(columnDefinitions)
    );
    const createRootGridMemoized = memoizeOne(createRootGrid);
    const createNoDataCellMemoized = memoizeOne((width: number) => {
        return (props: Table.NoDataCellProps) => {
            return <NoDataCell {...props} width={width} />;
        };
    });
    const createFormattedVirtualTableCellMemoized = memoizeOne(
        (
            getCellClassName: ((row: unknown, columnName: string, value: unknown) => string | undefined) | undefined,
            columnDefinitions: Array<GridColumnDefinition<T>>
        ) => {
            return (props: Table.DataCellProps) => {
                return (
                    <VirtualTableCell
                        {...props}
                        customRenderer={
                            columnDefinitions.find((columnDefinition) => columnDefinition.name === props.column.name)
                                ?.cellRender
                        }
                        getCellClassName={getCellClassName}
                    />
                );
            };
        }
    );
    const createFormattedTableGroupRowSummaryCellComponentMemoized = memoizeOne(
        (columnDefinitions: Array<GridColumnDefinition<T>>): FC<TableGroupRow.SummaryCellProps> => {
            return ({ children, column, ...restProps }) => {
                const columnDefinition = columnDefinitions.find((c) => c.name === column.name);
                const renderedChildren = columnDefinition?.cellRender
                    ? columnDefinition.cellRender(children)
                    : children;
                return (
                    <TableGroupRowSummaryCell column={column} {...restProps}>
                        {renderedChildren}
                    </TableGroupRowSummaryCell>
                );
            };
        }
    );
    const createTableFixedColumnsCellMemoized = memoizeOne(createTableFixedColumnsCell);
    const createTableGroupRowContentMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) =>
        createTableGroupRowContent(columnDefinitions)
    );
    const createGroupedGridGetRowIdMemoized = memoizeOne((getRowId: (row: T) => number | string) =>
        createGroupedGridGetRowId(getRowId)
    );
    const createTableColumnExtensionsMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) => {
        return columnDefinitions.filter((c) => c.align).map((c) => ({ align: c.align, columnName: c.name }));
    });
    const retrieveColumnExtensions = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) => {
        return columnDefinitions.filter((c) => c.minWidth).map((c) => ({ columnName: c.name, minWidth: c.minWidth }));
    });
    const createDataTypeProvidersMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) => {
        return columnDefinitions
            .map((c) => {
                if (!c.valueFormatterComponent) {
                    return undefined;
                }

                const ValueFormatterComponent = memo(c.valueFormatterComponent);
                const component = (props: DataTypeProvider.ValueFormatterProps) => (
                    <ValueFormatterComponent {...props} />
                );

                return <DataTypeProvider for={[c.name]} formatterComponent={component} key={c.name} />;
            })
            .filter(Boolean);
    });
    const createSortColumnExtensionsMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) => {
        return columnDefinitions
            .filter((c) => typeof c.compare === 'function')
            .map((c) => ({ columnName: c.name, compare: c.compare }));
    });
    const createHiddenColumnNamesMemoized = memoizeOne(
        (columnDefinitions: Array<GridColumnDefinition<T>>, visibleColumns: string[]) => {
            return without(
                columnDefinitions.map((c) => c.name),
                ...visibleColumns
            );
        }
    );
    const createGridColumnsMemoized = memoizeOne((columnDefinitions: Array<GridColumnDefinition<T>>) => {
        return columnDefinitions.map((c) => ({ getCellValue: c.getCellValue, name: c.name, title: c.title }));
    });
    const createIntegratedGroupingColumnExtensionsMemoized = memoizeOne(
        (columnDefinitions: Array<GridColumnDefinition<T>>) => {
            return columnDefinitions
                .filter((c) => c.groupingCriteria)
                .map((c) => ({ columnName: c.name, criteria: c.groupingCriteria }));
        }
    );
    const createGroupingStateColumnExtensionsMemoized = memoizeOne<GroupingStateProps<T>>(
        ({ columnDefinitions, groupBy, groupingEnabled }) => {
            if (groupingEnabled) {
                const disabledColumnDefinitions =
                    groupBy.length >= getConfig().maxGridColumnsToGroupBy
                        ? columnDefinitions.filter((c) => !groupBy.includes(c.name))
                        : columnDefinitions.filter(({ groupingDisabled }) => groupingDisabled);

                return disabledColumnDefinitions.map((c) => ({
                    columnName: c.name,
                    groupingEnabled: false,
                    type: 'count',
                }));
            }

            return [];
        }
    );
    const createHandleGroupingMemoized = memoizeOne((groupBy: string[]) => {
        return groupBy.map((columnName) => ({ columnName }));
    });
    const createHandleGroupingChangeMemoize = memoizeOne((changeGroupBy: (groupBy: string[]) => void) => {
        return (groupingArray: Grouping[]) => {
            changeGroupBy(groupingArray.map(({ columnName }) => columnName));
        };
    });
    const createSelectionStateMemoized = memoizeOne((selectedRowId?: GridRowId) =>
        isUndefined(selectedRowId) ? [] : [selectedRowId]
    );
    const createFilteringStateColumnExtensionsMemoized = memoizeOne(
        (columnDefinitions: Array<GridColumnDefinition<T>>): FilteringState.ColumnExtension[] => {
            return columnDefinitions
                .filter((c) => c.dataType === 'object')
                .map((c) => ({ columnName: c.name, filteringEnabled: false }));
        }
    );
    const createFilteringColumnExtensionsMemoized = memoizeOne(createFilteringColumnExtensions);
    const createFilterCellMemoized = memoizeOne(FilterCellFactory);

    const createCellValueGettersMemoized = memoizeOne((groupBy, gridColumns) => {
        return groupBy.map(
            (groupByColumn: string) =>
                gridColumns?.find((definition: GridColumnDefinition<T>) => definition?.name === groupByColumn)
                    ?.getCellValue
        );
    });

    const createAllGroupsIdsMemoized = memoizeOne(({ cellValueGetters, dataSource, groupingEnabled }): string[] => {
        if (!groupingEnabled || isEmpty(dataSource)) {
            return [];
        }
        const groupIds = dataSource
            .map((dataItem: T) => {
                return cellValueGetters
                    .map((getCellValue: ((dataItem: T) => void) | undefined) =>
                        getCellValue ? getCellValue(dataItem) : ''
                    )
                    .reduce((acc: string[], item: string) => acc.concat(acc.concat(item).join('|')), []);
            })
            .flat();
        return Array.from(new Set(groupIds));
    });

    const createAllRowsIdsMemoized = memoizeOne(({ dataSource, getRowId, RowDetail }) =>
        RowDetail ? dataSource.map(getRowId) : []
    );

    const GridComponent: FC<GridInnerProps<T>> = (props) => {
        const {
            changeColumnOrder,
            changeColumnWidths,
            changeFilters,
            changeGroupBy,
            changeSorting,
            columnDefinitions,
            columnOrder,
            columnWidths,
            currentSelection,
            dataSource,
            enableMultiselection,
            filteringEnabled,
            filters,
            forwardRef,
            getCellClassName,
            getRowId,
            gridDataId,
            groupBy,
            groupingEnabled,
            hideToolBar = false,
            highlightedRowId,
            multiSelection,
            noDataMessage,
            onRowContextMenu,
            pinnedColumns,
            RowDetail,
            selectedRowId,
            selectRow,
            size,
            sorting,
            summaryItems = [],
            visibleColumns,
        } = props;

        const { t } = useTranslation();
        const [expandedGroups, setExpandedGroups] = useState<GroupKey[]>([]);
        const rootGridComponent = createRootGridMemoized(gridDataId);
        const vtRef = useRef<typeof VirtualTable>(null);

        const noDataCellComponent = createNoDataCellMemoized(size.width);
        const tableFixedColumnsCell = createTableFixedColumnsCellMemoized(selectedRowId, highlightedRowId);

        const gridColumns = createGridColumnsMemoized(columnDefinitions);
        const title = createTableHeaderRowTitleMemoized(columnDefinitions);
        const tableColumnExtensions = createTableColumnExtensionsMemoized(columnDefinitions);
        const resizerColumnExtensions = retrieveColumnExtensions(columnDefinitions);
        const tableGroupRowContentComponent = createTableGroupRowContentMemoized(columnDefinitions);
        const formattedVirtualTableCellComponent = createFormattedVirtualTableCellMemoized(
            getCellClassName,
            columnDefinitions
        );
        const formattedTableGroupRowSummaryCellComponent =
            createFormattedTableGroupRowSummaryCellComponentMemoized(columnDefinitions);
        const dataTypeProviders = createDataTypeProvidersMemoized(columnDefinitions);
        const sortColumnExtensions = createSortColumnExtensionsMemoized(columnDefinitions);
        const hiddenColumnNames = createHiddenColumnNamesMemoized(columnDefinitions, visibleColumns);
        const filteringStateColumnExtensions = createFilteringStateColumnExtensionsMemoized(columnDefinitions);
        const integratedGroupingColumnExtensions = createIntegratedGroupingColumnExtensionsMemoized(columnDefinitions);
        const grouping = createHandleGroupingMemoized(groupBy);
        const handleGroupingChange = createHandleGroupingChangeMemoize(changeGroupBy);
        const groupingStateColumnExtensions = createGroupingStateColumnExtensionsMemoized({
            columnDefinitions,
            groupBy,
            groupingEnabled,
        });

        const cellValueGetters = groupingEnabled ? createCellValueGettersMemoized(groupBy, gridColumns) : [];
        const allGroupsIds = createAllGroupsIdsMemoized({ cellValueGetters, dataSource, groupingEnabled });
        const allRowsIds = createAllRowsIdsMemoized({ dataSource, getRowId, RowDetail });

        const filteringState = filteringEnabled && (
            <FilteringState
                columnExtensions={filteringStateColumnExtensions}
                filters={filters}
                onFiltersChange={changeFilters}
            />
        );
        const filteringColumnExtensions = createFilteringColumnExtensionsMemoized(columnDefinitions, visibleColumns);
        const integratedFiltering = filteringEnabled && (
            <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
        );

        const iconMessages = {
            [GridFilterOperation.After]: t('after'),
            [GridFilterOperation.AfterOrEqual]: t('after-or-equal'),
            [GridFilterOperation.Before]: t('before'),
            [GridFilterOperation.BeforeOrEqual]: t('before-or-equal'),
            [GridFilterOperation.Contains]: t('contains'),
            [GridFilterOperation.EndsWith]: t('ends-with'),
            [GridFilterOperation.Equal]: t('equal'),
            [GridFilterOperation.GreaterThan]: t('greater-than'),
            [GridFilterOperation.GreaterThanOrEqual]: t('greater-than-or-equal'),
            [GridFilterOperation.LessThan]: t('less-than'),
            [GridFilterOperation.LessThanOrEqual]: t('less-than-or-equal'),
            [GridFilterOperation.MonthEqual]: t('month-equal'),
            [GridFilterOperation.NotContains]: t('not-contains'),
            [GridFilterOperation.NotEqual]: t('not-equal'),
            [GridFilterOperation.StartsWith]: t('starts-with'),
            [GridFilterOperation.YearEqual]: t('year-equal'),
        };
        const filterCell = createFilterCellMemoized(columnDefinitions);

        const [selection, setSelection] = useState<(number | string)[]>([]);

        const changeSelection = (sel: GridRowId[]) => {
            setSelection(sel);

            if (multiSelection) {
                multiSelection(sel);
            }
        };

        useImperativeHandle(forwardRef, () => ({
            scrollIntoView: (gridRowId: GridRowId) => {
                if (vtRef.current) {
                    vtRef.current.scrollToRow(gridRowId);
                }
            },
        }));

        const selectedRowRef = useRef<GridRowId | undefined>(selectedRowId);
        const [expandedRowIds, setExpandedRowIds] = useState<(number | string)[]>([]);
        const [totalItems] = useState<SummaryItem[]>([{ columnName: visibleColumns[0], type: 'count' }]);
        const [groupItems] = useState<GroupSummaryItem[]>([
            { columnName: visibleColumns[0], showInGroupFooter: false, type: 'count' },
            ...summaryItems,
        ]);

        const isExpandAllButtonDisabled =
            (groupingEnabled && allGroupsIds?.length === expandedGroups?.length) ||
            (RowDetail && allRowsIds?.length === expandedRowIds?.length);
        const isCollapseAllButtonDisabled =
            (groupingEnabled && !expandedGroups?.length) || (RowDetail && !expandedRowIds?.length);

        const handleExpandAllButtonClick = useCallback(() => {
            if (groupingEnabled) {
                setExpandedGroups(allGroupsIds);
            }
            if (RowDetail) {
                setExpandedRowIds(allRowsIds);
            }
        }, [groupingEnabled, RowDetail, allGroupsIds, allRowsIds, setExpandedGroups, setExpandedRowIds]);

        const handleCollapseAllButtonClick = useCallback(() => {
            if (groupingEnabled) {
                setExpandedGroups([]);
            }
            if (RowDetail) {
                setExpandedRowIds([]);
            }
        }, [groupingEnabled, RowDetail, setExpandedGroups, setExpandedRowIds]);

        return (
            <div style={size}>
                <Grid
                    columns={gridColumns}
                    getRowId={createGroupedGridGetRowIdMemoized(getRowId)}
                    key="dense"
                    rootComponent={rootGridComponent}
                    rows={dataSource}
                >
                    {dataTypeProviders}
                    <DragDropProvider />

                    {filteringState}
                    <SelectionState
                        onSelectionChange={changeSelection}
                        selection={enableMultiselection ? selection : createSelectionStateMemoized(selectedRowId)}
                    />
                    <SortingState onSortingChange={changeSorting} sorting={sorting} />
                    <GroupingState
                        columnExtensions={groupingStateColumnExtensions}
                        columnGroupingEnabled={groupingEnabled}
                        expandedGroups={expandedGroups}
                        grouping={groupingEnabled ? grouping : []}
                        onExpandedGroupsChange={setExpandedGroups}
                        onGroupingChange={handleGroupingChange}
                    />

                    {integratedFiltering}
                    <IntegratedSelection />
                    <IntegratedSorting columnExtensions={sortColumnExtensions} />
                    <IntegratedGrouping columnExtensions={integratedGroupingColumnExtensions} />

                    <VirtualTable
                        cellComponent={formattedVirtualTableCellComponent}
                        columnExtensions={tableColumnExtensions}
                        estimatedRowHeight={rowHeight}
                        height="auto"
                        messages={{ noData: noDataMessage || t('no-data') }}
                        noDataCellComponent={noDataCellComponent}
                        ref={vtRef}
                        // eslint-disable-next-line react/no-unstable-nested-components
                        rowComponent={(rowProps) => (
                            <TableRow
                                highlightedRowId={highlightedRowId}
                                onRowContextMenu={onRowContextMenu}
                                selectedRowRef={selectedRowRef}
                                selectRow={selectRow}
                                {...rowProps}
                            />
                        )}
                    />

                    <TableColumnVisibility
                        emptyMessageComponent={EmptyMessage}
                        hiddenColumnNames={hiddenColumnNames}
                        messages={{ noColumns: t('no-columns-selected') }}
                    />
                    <TableColumnReordering onOrderChange={changeColumnOrder} order={columnOrder} />
                    <TableColumnResizing
                        columnExtensions={resizerColumnExtensions}
                        columnWidths={columnWidths}
                        minColumnWidth={minColumnWidth}
                        onColumnWidthsChange={changeColumnWidths}
                    />
                    {RowDetail && (
                        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
                    )}

                    <TableHeaderRow
                        cellComponent={HeaderRowCell}
                        messages={{ sortingHint: '' }}
                        showSortingControls
                        sortLabelComponent={SortLabel}
                        titleComponent={title}
                    />

                    {RowDetail && <TableRowDetail contentComponent={RowDetail} />}

                    {filteringEnabled && (
                        <TableFilterRow
                            cellComponent={filterCell}
                            iconComponent={FilterIcon}
                            messages={iconMessages}
                            showFilterSelector
                        />
                    )}

                    <TableSelection showSelectAll={enableMultiselection} showSelectionColumn={enableMultiselection} />
                    {(groupingEnabled || enableMultiselection) && (
                        <SummaryState groupItems={groupItems} totalItems={totalItems} />
                    )}
                    {(groupingEnabled || enableMultiselection) && <IntegratedSummary />}
                    {groupingEnabled && (
                        <TableGroupRow
                            cellComponent={TableGroupRowCell}
                            contentComponent={tableGroupRowContentComponent}
                            inlineSummaryItemComponent={InlineSummary}
                            messages={{ countOf: '', max: '', min: '', sum: '' }}
                            showColumnsWhenGrouped
                            summaryCellComponent={formattedTableGroupRowSummaryCellComponent}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            summaryItemComponent={(summaryProps) => (
                                <div>{summaryProps.children || summaryProps.value}</div>
                            )}
                        />
                    )}
                    {(groupingEnabled || enableMultiselection) && (
                        <TableSummaryRow
                            // eslint-disable-next-line react/no-unstable-nested-components
                            totalRowComponent={(tableSummaryProps) =>
                                enableMultiselection && currentSelection ? (
                                    <TotalCounterFooter
                                        message={t('selected-of-total', {
                                            selected: currentSelection.length,
                                            total: dataSource.length,
                                        })}
                                        {...tableSummaryProps}
                                    />
                                ) : (
                                    <TotalCounterFooter
                                        message={t('total-rows')}
                                        totalCountElements={dataSource.length}
                                        {...tableSummaryProps}
                                    />
                                )
                            }
                        />
                    )}

                    {/* Keep TableFixedColumns after TableGroupRow to avoid losing the right divider */}
                    {/* For more details check https://github.com/DevExpress/devextreme-reactive/issues/1913 */}
                    <TableFixedColumns
                        // Use custom component to fix https://github.com/DevExpress/DevExtreme/issues/7475
                        // Get back to the default implementation after it's getting fixed
                        cellComponent={tableFixedColumnsCell}
                        leftColumns={pinnedColumns}
                    />

                    {((groupingEnabled && !isEmpty(groupBy)) || RowDetail) && (
                        <Toolbar
                            // eslint-disable-next-line react/no-unstable-nested-components
                            rootComponent={(toolbarProps) => (
                                <ToolbarRoot {...toolbarProps}>
                                    <ExpandCollapseControls
                                        isCollapseAllButtonDisabled={isCollapseAllButtonDisabled}
                                        isExpandAllButtonDisabled={isExpandAllButtonDisabled}
                                        onCollapseAllButtonClick={handleCollapseAllButtonClick}
                                        onExpandAllButtonClick={handleExpandAllButtonClick}
                                    />
                                </ToolbarRoot>
                            )}
                        />
                    )}

                    <Toolbar
                        // eslint-disable-next-line react/no-unstable-nested-components
                        rootComponent={(toolbarProps) => (
                            <ToolbarRoot {...toolbarProps} isHidden={hideToolBar || !groupingEnabled} />
                        )}
                    />

                    <GroupingPanel
                        containerComponent={GroupingPaneContainer}
                        messages={{ groupByColumn: t('drag-a-column-header-to-group-by-that-column') }}
                        showGroupingControls
                        showSortingControls
                    />
                </Grid>
            </div>
        );
    };

    return GridComponent;
};
