import type { Dictionary } from '~/libs/utility';

import { carousel } from './assets/v30';

export interface CarouselDefinition {
    assets: Dictionary<string>;
    autoPlaySpeed?: number;
    customUrl?: Dictionary<string>;
    fallback: CarouselLanguageDefinition;
    languages: Dictionary<CarouselLanguageOverrideDefinition>;
    revision: number;
}

export interface CarouselLanguageDefinition {
    slides: Dictionary<CarouselSlideLanguageDefinition>;
}

export interface CarouselLanguageOverrideDefinition {
    slides: Dictionary<Partial<CarouselSlideLanguageDefinition>>;
}

export interface CarouselSlideLanguageDefinition {
    subtitle: string;
    title: string;
}

export const getCarousel = (): CarouselDefinition => {
    return carousel;
};
