import 'leaflet/dist/leaflet.css';
import type { Map as LMap } from 'leaflet';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ZoomControl } from 'react-leaflet';
import Control from 'react-leaflet-custom-control';

import { ControlButton, MapEventControls, Tiles } from './components';
import { BOUNDS, ZOOM } from './consts';
import type { MapProps } from './models';
import { MapContainer, MyLocation } from './styles';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';

const Map = forwardRef<LMap | null, MapProps>((props, ref) => {
    const {
        bounds = BOUNDS.DEFAULT,
        children,
        contextMenuItems,
        dragging = false,
        mapEvents,
        relocate,
        scrollWheelZoom = true,
        tiles,
        touchZoom = false,
        zoom = 13,
        zoomControl = false,
        ...mapProps
    } = props;

    const mapRef = useRef<LMap | null>();
    useImperativeHandle<LMap | null, LMap | null>(ref, () => mapRef?.current ?? null, []);
    const { t } = useTranslation();

    return (
        <MapContainer
            {...mapProps}
            bounds={bounds}
            contextmenu={Boolean(contextMenuItems?.length)}
            contextmenuItems={contextMenuItems || []}
            doubleClickZoom={false}
            dragging={dragging}
            keyboard={false}
            maxBounds={BOUNDS.MAX_BOUND}
            maxZoom={ZOOM.MAX}
            minZoom={ZOOM.MIN}
            preferCanvas
            ref={(instance) => {
                mapRef.current = instance;
            }}
            scrollWheelZoom={scrollWheelZoom}
            touchZoom={touchZoom}
            zoom={zoom ?? ZOOM.DEFAULT}
            zoomControl={zoomControl}
        >
            <Tiles tiles={tiles} />

            {children}

            {relocate && (
                <Control
                    container={{ style: { border: '2px solid rgba(0,0,0,0.2)', borderRadius: '4px' } }}
                    position="bottomright"
                    prepend
                >
                    <ControlButton
                        isDisable={relocate.isDisable}
                        onClick={() => {
                            if (mapRef.current) {
                                relocate?.onClick(mapRef.current);
                            }
                        }}
                        title={t('common-actions.locate')}
                    >
                        <MyLocation />
                    </ControlButton>
                </Control>
            )}

            <ZoomControl
                data-testid="zoom-control"
                position="bottomright"
                zoomInTitle={t('zoom-in')}
                zoomOutTitle={t('zoom-out')}
            />

            <MapEventControls {...mapEvents} />
        </MapContainer>
    );
});

Map.displayName = 'Map';
export { Map };
