import * as React from 'react';

import { DetailsPaneHeader } from '~/components/DetailsPaneHeader';
import type { InjectedDisplayPreferencesProps } from '~/components/DisplayPreferences';
import type { InjectedTranslationProps } from '~/components/LanguageSelector';
import { SceneDetailsPaneFactory } from '~/components/SceneDetailsPane';
import { formatDriverName } from '~/services/Formatters';

import type { HistoryDriverEntry } from '../../../../models';

import type { SectionName } from './constants';
import type { HistoryDriverDetailsPaneUserPreferences } from './preferences';
import { HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY } from './preferences';
import { getSectionsMemoized } from './sections';

export interface DriverDetailsPaneProps {
    entry: HistoryDriverEntry;
    handleClose: () => void;
}

export interface DispatchProps {
    changeMiniMapZoomLevel: (zoomLevel: number) => void;
}

export interface DriverDetailsPaneInnerProps
    extends DriverDetailsPaneProps,
        InjectedDisplayPreferencesProps,
        DispatchProps,
        InjectedTranslationProps {}

const DetailsPaneComponent = SceneDetailsPaneFactory<
    HistoryDriverEntry,
    HistoryDriverDetailsPaneUserPreferences,
    SectionName
>(HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY);

export const DriverDetailsPaneComponent: React.FC<DriverDetailsPaneInnerProps> = ({
    changeMiniMapZoomLevel,
    displayPreferences,
    entry,
    handleClose,
    t,
}) => {
    const getSections = (
        userPreferences: HistoryDriverDetailsPaneUserPreferences,
        toggleCollapsedState: (sectionName: SectionName) => () => void
    ) =>
        getSectionsMemoized(
            entry,
            userPreferences.collapsedSections,
            toggleCollapsedState,
            userPreferences.miniMapZoomLevel,
            changeMiniMapZoomLevel
        );

    const driverName = entry.driver
        ? formatDriverName(entry.driver, displayPreferences.driverDisplayFormat)
        : undefined;
    const header = <DetailsPaneHeader onClose={handleClose} title={t('driver-event-of', { driverName })} />;

    return (
        <DetailsPaneComponent
            dataId="driver-details-pane"
            entry={entry}
            getSections={getSections}
            HeaderReactNode={header}
        />
    );
};
