import { LocationSection, MiniMapSectionFactory } from '~/common';
import { TrailerMarker } from '~/components/Map';
import type { SectionDefinition } from '~/components/Sections';
import type { MonitoringTrailerEntry } from '~/data/monitoring';
import { MonitoringPerspective } from '~/data/monitoring';
import { monitoringTrailerEntryEquals } from '~/scenes/Monitoring/utils';
import { ApiClient, AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { AssetHistorySubpageFactory } from '../AssetHistorySubpage';
import { LocationSectionActionButtons } from '../LocationSectionActionButtons';
import { TemperaturesSection } from '../TemperaturesSection';

import { DoorsSection } from './components/DoorsSection';
import { EbsSection } from './components/EbsSection';
import { ReeferSection } from './components/ReeferSection';
import { VehicleSection } from './components/VehicleSection';
import { SectionName } from './constants';
import type { SectionProps } from './model';

const MiniMapSectionComponent = MiniMapSectionFactory<MonitoringTrailerEntry>(
    (entry: MonitoringTrailerEntry) => entry.trailer.id,
    async ({ endDate, id, startDate }) => {
        const { items } = await ApiClient.getTrailerPositions(id, startDate, endDate);
        return items;
    },
    monitoringTrailerEntryEquals
);

export const TrailerHistorySubpage = AssetHistorySubpageFactory(AssetType.Trailer);

export const getSections = (props: SectionProps): SectionDefinition[] => {
    const {
        changeCollapsedCompartments,
        changeMiniMapZoomLevel,
        collapsedCompartments,
        collapsedSections,
        entry,
        handleLocate,
        miniMapZoomLevel,
        toggleCollapsedState,
        viewMode,
    } = props;
    const locationSectionActionButtons = (
        <LocationSectionActionButtons
            assetId={entry.trailer.id}
            onLocate={handleLocate}
            perspective={MonitoringPerspective.TRAILER}
            position={entry.status?.location?.position}
            showHistoryButton
            viewMode={viewMode}
        />
    );
    const sections = [
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    actionButtons={locationSectionActionButtons}
                    address={entry.status?.location?.address}
                    datetime={entry.status?.location?.dateTime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    position={entry.status?.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <MiniMapSectionComponent
                    AssetMarker={TrailerMarker}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    lastLocation={entry.status?.location?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <VehicleSection
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLE)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLE)}
                />
            ),
            name: SectionName.VEHICLE,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <TemperaturesSection
                    changeCollapsedCompartments={changeCollapsedCompartments}
                    collapsedCompartments={collapsedCompartments}
                    compartments={Object.values(entry.status?.compartments ?? {})}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.TEMPERATURES)}
                    toggleCollapsed={toggleCollapsedState(SectionName.TEMPERATURES)}
                    trailer={entry.trailer}
                />
            ),
            name: SectionName.TEMPERATURES,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <DoorsSection
                    doors={Object.values(entry.status?.doors ?? {})}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.DOORS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.DOORS)}
                />
            ),
            name: SectionName.DOORS,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ReeferSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.REEFER)}
                    reefer={entry.status?.reefer}
                    toggleCollapsed={toggleCollapsedState(SectionName.REEFER)}
                />
            ),
            name: SectionName.REEFER,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <EbsSection
                    dragHandleElement={dragHandleElement}
                    ebs={entry.status?.ebs}
                    isCollapsed={collapsedSections.includes(SectionName.EBS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.EBS)}
                />
            ),
            name: SectionName.EBS,
        },
    ];

    return sections;
};

export const unauthorizedSectionsNames = (canViewMap: boolean): SectionName[] => {
    const result = [];

    if (!canViewMap) {
        result.push(SectionName.MINIMAP);
    }

    return result;
};

export const getSectionsMemoized = memoizeOne(getSections);
export const unauthorizedSectionsNamesMemoized = memoizeOne(unauthorizedSectionsNames);
