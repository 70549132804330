import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY = 'history-detailspane-trailer-state';

export interface HistoryTrailerDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const defaultHistoryTrailerDetailsPaneUserPreferences: HistoryTrailerDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MINIMAP, SectionName.LOCATION],
};

export const processResponse = (
    preferences: Partial<HistoryTrailerDetailsPaneUserPreferences>
): HistoryTrailerDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultHistoryTrailerDetailsPaneUserPreferences);
};

export const requiredHistoryTrailerDetailsPaneUserPreferences = {
    defaultState: defaultHistoryTrailerDetailsPaneUserPreferences,
    key: HISTORY_DETAILSPANE_TRAILER_USERPREFERENCES_KEY,
    processResponse,
};
