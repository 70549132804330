import InputAdornment from '@mui/material/InputAdornment';
import type { FC } from 'react';

import type { TextInputProps } from './models';
import { TextField } from './styles';

const TextInput: FC<TextInputProps> = (props) => {
    const { endAdornment, InputProps, ...restProps } = props;

    const extendedInputProps: TextInputProps['InputProps'] = {
        ...InputProps,
        endAdornment:
            (endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>) ||
            InputProps?.endAdornment,
    };

    return <TextField {...restProps} InputProps={extendedInputProps} />;
};

TextInput.displayName = 'TextInput';
export { TextInput };
