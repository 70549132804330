import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY = 'history-detailspane-driver-state';

export interface HistoryDriverDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    miniMapZoomLevel: number;
}

export const defaultHistoryDriverDetailsPaneUserPreferences: HistoryDriverDetailsPaneUserPreferences = {
    collapsedSections: [],
    miniMapZoomLevel: 12,
    sectionOrder: [SectionName.MINIMAP, SectionName.LOCATION, SectionName.ACTIVITYFIELDS],
};

export const processResponse = (
    preferences: Partial<HistoryDriverDetailsPaneUserPreferences>
): HistoryDriverDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultHistoryDriverDetailsPaneUserPreferences);
};

export const requiredHistoryDriverDetailsPaneUserPreferences = {
    defaultState: defaultHistoryDriverDetailsPaneUserPreferences,
    key: HISTORY_DETAILSPANE_DRIVER_USERPREFERENCES_KEY,
    processResponse,
};
