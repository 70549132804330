import type { LatLngTuple } from 'leaflet';
import { LatLngBounds } from 'leaflet';

import { ensureBoundsArea } from '~/components/SceneMap/components/SceneMapView/services';
import type { PositionGroupsMeta } from '~/data/monitoring';
import { isNil, isUndefined } from '~/libs/utility';
import type { RetrievableData } from '~/reducers';
import type { PositionGroup, PositionGroupType, PositionStatus } from '~/services/ApiClient';
import { getConfig } from '~/services/Config';

export enum LatLngOrder {
    LatLng = 0,
    LngLat = 1,
}

export enum PositionGroupState {
    Highlighted = 'highlighted',
    Normal = 'normal',
}

export const getAggregatedCoordinates = (
    positionGroups: PositionGroup[],
    // eslint-disable-next-line default-param-last
    latLngOrder: LatLngOrder = LatLngOrder.LatLng,
    ofType?: PositionGroupType
): LatLngTuple[] => {
    const positions = positionGroups
        .filter((it) => isNil(ofType) || it.type === ofType)
        .map((it) => it.positions ?? [])
        .reduce((accumulator, current) => {
            accumulator.push(...current);
            return accumulator;
        }, []);

    const coordinates: LatLngTuple[] = positions
        .filter((it) => !isNil(it.latitude) && !isNil(it.longitude))
        .map((it) => (latLngOrder === LatLngOrder.LatLng ? [it.latitude, it.longitude] : [it.longitude, it.latitude]));

    return coordinates;
};

export const getRouteBounds = (positions?: LatLngTuple[]): LatLngBounds => {
    return positions?.length
        ? ensureBoundsArea(new LatLngBounds(positions))
        : new LatLngBounds(getConfig().defaultMapBounds);
};

export const getPositionStatusLatLong = (positionStatus?: PositionStatus): LatLngTuple | undefined => {
    return positionStatus?.position ? [positionStatus.position.latitude, positionStatus.position.longitude] : undefined;
};

export const getPositionGroupLatLong = (positionGroup?: PositionGroup): LatLngTuple | undefined => {
    if (isNil(positionGroup)) {
        return undefined;
    }

    if (!isNil(positionGroup.current)) {
        return getPositionStatusLatLong(positionGroup.current);
    }

    if (!isNil(positionGroup.start)) {
        return getPositionStatusLatLong(positionGroup.start);
    }

    if (!isNil(positionGroup.stop)) {
        return getPositionStatusLatLong(positionGroup.stop);
    }

    return undefined;
};

export const positionGroupsAreLoaded = (
    positionGroups: RetrievableData<PositionGroup[]>,
    pendingPositionGroups?: PositionGroupsMeta
): boolean => {
    if (
        !isUndefined(pendingPositionGroups) ||
        isUndefined(positionGroups) ||
        !positionGroups.fulfilled ||
        isUndefined(positionGroups.data)
    ) {
        return false;
    }

    return true;
};

export const getPositionGroupMarkerDataId = (
    positionGroup: PositionGroup,
    positionGroupState: PositionGroupState
): string => {
    let markerType: string = positionGroup.current ? 'current' : '';

    markerType = `${markerType}${positionGroup.type}`;

    return `position-group-marker:${positionGroup.id}:${markerType}:${positionGroupState}`;
};

export const getPositionGroupRouteDataId = (
    positionGroup: PositionGroup,
    positionGroupState: PositionGroupState
): string => {
    return `position-group-route:${positionGroup.id}:${positionGroup.type}:${positionGroupState}`;
};
