import type { AnyAction, Reducer } from 'redux';
import type { PersistPartial } from 'redux-persist/es/persistReducer';

import type { StaticDataStoreState } from '~/common';
import { staticDataReducer } from '~/common';
import type { JwtStoreState } from '~/components/AuthenticationData';
import { protectedComponentReducer } from '~/components/AuthenticationData';
import type { CustomerAutocompleteStoreState } from '~/components/CustomerRemoteAutocomplete';
import { customerAutocompleteReducer } from '~/components/CustomerRemoteAutocomplete';
import type { SettingsStoreState } from '~/components/EnsureSettings';
import { settingsReducer } from '~/components/EnsureSettings';
import type { EtherealState } from '~/components/EtherealState';
import { etherealReducer } from '~/components/EtherealState';
import type { FeatureFlagsStoreState } from '~/components/FeatureFlags';
import { featureFlagsReducer } from '~/components/FeatureFlags';
import type { IntroCarouselStoreState } from '~/components/IntroCarousel';
import { introCarouselReducer } from '~/components/IntroCarousel';
import type { LanguageStoreState } from '~/components/LanguageSelector';
import { languageComponentReducer } from '~/components/LanguageSelector';
import type { MenuStoreState } from '~/components/Navigation';
import { menuReducer } from '~/components/Navigation';
import type { PersistStoreState } from '~/components/PersistStore';
import { persistStoreReducer } from '~/components/PersistStore';
import type { SceneListRootStoreState } from '~/components/SceneList';
import { sceneListReducer } from '~/components/SceneList';
import type { SceneMapRootStoreState } from '~/components/SceneMap';
import { sceneMapReducer } from '~/components/SceneMap';
import type { SynchronizationClockState } from '~/components/SynchronizationClock';
import { synchronizationClockReducer } from '~/components/SynchronizationClock';
import type { TimeSeriesDialogStoreState } from '~/components/TimeSeriesDialog';
import { timeSeriesDialogReducer } from '~/components/TimeSeriesDialog';
import type { UserPreferencesState } from '~/components/UserPreferences';
import { userPreferenceReducer } from '~/components/UserPreferences';
import type { UserAutocompleteStoreState } from '~/components/UserRemoteAutocomplete';
import { userAutocompleteReducer } from '~/components/UserRemoteAutocomplete';
import { assetNearByPositionsReducer } from '~/data/assetNearBy/reducer';
import { ActionTypeKeys as AuthenticationActionTypeKeys } from '~/data/authentication';
import type { CommunicationModuleStoreState } from '~/modules/Communication';
import { communicationModuleReducer } from '~/modules/Communication';
import type { AuthenticationSceneStoreState } from '~/scenes/Authentication';
import { authenticationSceneReducer } from '~/scenes/Authentication';
import type { HistorySceneStoreState } from '~/scenes/History';
import { historySceneReducer } from '~/scenes/History';
import type { MonitoringSceneStoreState } from '~/scenes/Monitoring';
import { monitoringSceneReducer } from '~/scenes/Monitoring';
import type { ResetPasswordStoreState } from '~/scenes/ResetPassword/reducers';
import { resetPasswordReducer } from '~/scenes/ResetPassword/reducers';
import type { RtdsSessionsSceneStoreState } from '~/scenes/RtdsSessions';
import { rtdsSessionsSceneReducer } from '~/scenes/RtdsSessions';
import type { TachoFilesSceneStoreState } from '~/scenes/TachoFiles';
import { tachoFilesSceneReducer } from '~/scenes/TachoFiles';
import type { AdminVehicleTypeSceneStoreState } from '~/scenes/VehicleTypesAdministration';
import { adminVehicleTypeSceneReducer } from '~/scenes/VehicleTypesAdministration';

import type { AlertDefinitionsState } from './data/alertDefinitions';
import { alertDefinitionsSceneReducer } from './data/alertDefinitions';
import type { AlertsState } from './data/alerts';
import { alertsSceneReducer } from './data/alerts';
import type { AssetGroupsAdminState } from './data/assetGroupsAdministration';
import { assetGroupsAdminReducer } from './data/assetGroupsAdministration';
import type { AssetNearBySceneState } from './data/assetNearBy';
import type { CommunicationStoreState } from './data/communication';
import type { AdminCompanyCardsState } from './data/companycardAdministration';
import { adminCompanyCardsSceneReducer } from './data/companycardAdministration';
import type { DevicesAdministrationState } from './data/devicesAdministration';
import { devicesAdministrationReducer } from './data/devicesAdministration';
import type { DriverActivitiesReportSceneState } from './data/driverActivitiesReport';
import { driverActivitiesReportReducer } from './data/driverActivitiesReport';
import type { AdminDriverStoreState } from './data/driveradministration';
import { adminDriverReducer } from './data/driveradministration';
import type { GeozonesAdministrationState } from './data/geozonesAdministration';
import { geozonesAdministrationReducer } from './data/geozonesAdministration';
import type { NotificationsState } from './data/notifications';
import { notificationReducer } from './data/notifications';
import type { ReportSettingsState } from './data/reportSettings';
import { reportSettingsReducer } from './data/reportSettings';
import type { TachoLincStatusState } from './data/tacholincStatus';
import { tachoLincStatusSceneReducer } from './data/tacholincStatus';
import type { TrailersAdministrationState } from './data/trailersAdministration';
import { trailersAdministrationReducer } from './data/trailersAdministration';
import type { TripReportState } from './data/tripReport';
import { tripReportReducer } from './data/tripReport';
import type { UsersAdministrationState } from './data/userAdministration';
import { usersAdministrationReducer } from './data/userAdministration/reducers';
import type { UserRolesState } from './data/userRoles';
import { userRolesSceneReducer } from './data/userRoles';
import { pick } from './libs/utility';
import type { NumericDictionary } from './libs/utility';
import { communicationReducer } from './scenes/Communication/reducers';
import type { RtdsSchedulesSceneStoreState } from './scenes/RtdsSchedules/storestate';
import { rtdsSchedulesSceneReducer } from './scenes/RtdsSchedules/storestate';
import type { AdminVehicleSceneStoreState } from './scenes/VehicleAdministration/storestate';
import { adminVehicleSceneReducer } from './scenes/VehicleAdministration/storestate';

export interface StoreState {
    readonly alertDefinitionsScene: AlertDefinitionsState;
    readonly alertsScene: AlertsState;
    readonly assetGroupAdminScene: AssetGroupsAdminState;
    readonly assetNearByScene: AssetNearBySceneState;
    readonly authenticationScene: AuthenticationSceneStoreState;
    readonly communicationModule: CommunicationModuleStoreState;
    readonly communicationScene: CommunicationStoreState;
    readonly companyCardAdministrationScene: AdminCompanyCardsState;
    readonly customerAutocomplete: CustomerAutocompleteStoreState;
    readonly deviceAdministrationScene: DevicesAdministrationState;
    readonly driverActivitiesReportScene: DriverActivitiesReportSceneState;
    readonly driverAdministrationScene: AdminDriverStoreState;
    readonly ethereal: EtherealState;
    readonly featureFlags: FeatureFlagsStoreState & PersistPartial;
    readonly geozoneAdministrationScene: GeozonesAdministrationState;
    readonly historyScene: HistorySceneStoreState;
    readonly introCarousel: IntroCarouselStoreState;
    readonly language: LanguageStoreState;
    readonly menu: MenuStoreState;
    readonly monitoringScene: MonitoringSceneStoreState;
    readonly notifications: NotificationsState;
    readonly persistStore: PersistStoreState;
    readonly protectedComponent: JwtStoreState & PersistPartial;
    readonly reportSettingsScene: ReportSettingsState;
    readonly resetPasswordScene: ResetPasswordStoreState;
    readonly rtdsSchedulesScene: RtdsSchedulesSceneStoreState;
    readonly rtdsSessionsScene: RtdsSessionsSceneStoreState;
    readonly sceneList: SceneListRootStoreState;
    readonly sceneMap: SceneMapRootStoreState;
    readonly settings: SettingsStoreState;
    readonly staticData: StaticDataStoreState;
    readonly synchronizationClock: SynchronizationClockState;
    readonly tachoFilesScene: TachoFilesSceneStoreState;
    readonly tachoLincStatusScene: TachoLincStatusState;
    readonly timeSeriesDialog: TimeSeriesDialogStoreState;
    readonly trailersAdministrationScene: TrailersAdministrationState;
    readonly tripReportScene: TripReportState;
    readonly userAdministrationScene: UsersAdministrationState;
    readonly userAutocomplete: UserAutocompleteStoreState;
    readonly userPreferences: UserPreferencesState;
    readonly userRolesScene: UserRolesState;
    readonly vehicleAdministrationScene: AdminVehicleSceneStoreState;
    readonly vehicleTypesAdministrationScene: AdminVehicleTypeSceneStoreState;
}

export const appReducer: Reducer<StoreState> = (storeState, action): StoreState => {
    const persistStore = persistStoreReducer(storeState?.persistStore, action);
    const protectedComponent = protectedComponentReducer(storeState?.protectedComponent, action);
    const authenticationScene = authenticationSceneReducer(storeState?.authenticationScene, action);
    const language = languageComponentReducer(storeState?.language, action);
    const staticData = staticDataReducer(storeState?.staticData, action);
    const settings = settingsReducer(storeState?.settings, action);
    const monitoringScene = monitoringSceneReducer(storeState?.monitoringScene, action);
    const sceneList = sceneListReducer(storeState?.sceneList, action);
    const sceneMap = sceneMapReducer(storeState?.sceneMap, action);
    const timeSeriesDialog = timeSeriesDialogReducer(storeState?.timeSeriesDialog, action);
    const synchronizationClock = synchronizationClockReducer(storeState?.synchronizationClock, action);
    const userPreferences = userPreferenceReducer(storeState?.userPreferences, action);
    const ethereal = etherealReducer(storeState?.ethereal, action);
    const introCarousel = introCarouselReducer(storeState?.introCarousel, action);
    const communicationModule = communicationModuleReducer(storeState?.communicationModule, action);
    const menu = menuReducer(storeState?.menu, action);
    const featureFlags = featureFlagsReducer(storeState?.featureFlags, action);
    const historyScene = historySceneReducer(storeState?.historyScene, action, settings);
    const tachoFilesScene = tachoFilesSceneReducer(storeState?.tachoFilesScene, action, staticData, settings);
    const rtdsSessionsScene = rtdsSessionsSceneReducer(storeState?.rtdsSessionsScene, action, staticData, settings);
    const rtdsSchedulesScene = rtdsSchedulesSceneReducer(storeState?.rtdsSchedulesScene, action, staticData, settings);
    const communicationScene = communicationReducer(
        storeState?.communicationScene,
        action,
        communicationModule.conversationsRoot,
        staticData,
        settings
    );
    const customerAutocomplete = customerAutocompleteReducer(storeState?.customerAutocomplete, action);
    const userAutocomplete = userAutocompleteReducer(storeState?.userAutocomplete, action);
    const vehicleAdministrationScene = adminVehicleSceneReducer(
        storeState?.vehicleAdministrationScene,
        action,
        staticData,
        settings
    );
    const driverAdministrationScene = adminDriverReducer(
        storeState?.driverAdministrationScene,
        action,
        staticData,
        settings
    );

    const resetPasswordScene = resetPasswordReducer(storeState?.resetPasswordScene, action);

    const vehicleTypesAdministrationScene = adminVehicleTypeSceneReducer(
        storeState?.vehicleTypesAdministrationScene,
        action,
        settings
    );

    const deviceAdministrationScene = devicesAdministrationReducer(
        storeState?.deviceAdministrationScene,
        action,
        settings,
        staticData
    );

    const companyCardAdministrationScene = adminCompanyCardsSceneReducer(
        storeState?.companyCardAdministrationScene,
        action,
        settings
    );

    const trailersAdministrationScene = trailersAdministrationReducer(
        storeState?.trailersAdministrationScene,
        action,
        settings,
        staticData
    );

    const tachoLincStatusScene = tachoLincStatusSceneReducer(storeState?.tachoLincStatusScene, action, staticData);
    const tripReportScene = tripReportReducer(storeState?.tripReportScene, action, staticData);
    const assetGroupAdminScene = assetGroupsAdminReducer(storeState?.assetGroupAdminScene, action, staticData);
    const driverActivitiesReportScene = driverActivitiesReportReducer(
        storeState?.driverActivitiesReportScene,
        action,
        staticData
    );
    const userRolesScene = userRolesSceneReducer(storeState?.userRolesScene, action);

    const alertDefinitionsScene = alertDefinitionsSceneReducer(storeState?.alertDefinitionsScene, action);

    const userAdministrationScene = usersAdministrationReducer(
        storeState?.userAdministrationScene,
        action,
        staticData,
        userRolesScene
    );

    const alertsScene = alertsSceneReducer(storeState?.alertsScene, action, staticData);

    const geozoneAdministrationScene = geozonesAdministrationReducer(
        storeState?.geozoneAdministrationScene,
        action,
        settings
    );

    const notifications = notificationReducer(storeState?.notifications, action);
    const reportSettingsScene = reportSettingsReducer(storeState?.reportSettingsScene, action);

    const assetNearByScene = assetNearByPositionsReducer(storeState?.assetNearByScene, action, staticData);

    return {
        alertDefinitionsScene,
        alertsScene,
        assetGroupAdminScene,
        assetNearByScene,
        authenticationScene,
        communicationModule,
        communicationScene,
        companyCardAdministrationScene,
        customerAutocomplete,
        deviceAdministrationScene,
        driverActivitiesReportScene,
        driverAdministrationScene,
        ethereal,
        featureFlags,
        geozoneAdministrationScene,
        historyScene,
        introCarousel,
        language,
        menu,
        monitoringScene,
        notifications,
        persistStore,
        protectedComponent,
        reportSettingsScene,
        resetPasswordScene,
        rtdsSchedulesScene,
        rtdsSessionsScene,
        sceneList,
        sceneMap,
        settings,
        staticData,
        synchronizationClock,
        tachoFilesScene,
        tachoLincStatusScene,
        timeSeriesDialog,
        trailersAdministrationScene,
        tripReportScene,
        userAdministrationScene,
        userAutocomplete,
        userPreferences,
        userRolesScene,
        vehicleAdministrationScene,
        vehicleTypesAdministrationScene,
    };
};

const impersonateUserPersistentReducers = [
    'persistStore',
    'protectedComponent',
    'authenticationScene',
    'language',
    'synchronizationClock',
    'menu',
    'featureFlags',
];

export const rootReducer = (state: StoreState, action: AnyAction): StoreState => {
    let nextState = appReducer(state, action);

    if (action.type === AuthenticationActionTypeKeys.IMPERSONATEUSER_FULFILLED) {
        nextState = appReducer(pick(nextState, impersonateUserPersistentReducers) as StoreState, { type: undefined });
    }

    if (action.type === AuthenticationActionTypeKeys.LOGOUT_FULFILLED) {
        nextState = appReducer(undefined, { type: undefined });
    }

    if (action.type === AuthenticationActionTypeKeys.IMPERSONATIONENDED_FULFILLED) {
        nextState = appReducer(pick(nextState, impersonateUserPersistentReducers) as StoreState, { type: undefined });
    }

    return nextState;
};

export interface RetrievableDataStatus {
    fulfilled: boolean;
    pending: boolean;
    rejected: boolean;
}

export interface RetrievableData<T> extends RetrievableDataStatus {
    data: T;
}

export type HashedData<T> = { array: Array<T>; hash: NumericDictionary<T> };

export interface RetrievableHashedData<T> extends RetrievableDataStatus {
    data: HashedData<T>;
}
