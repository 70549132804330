/* eslint-disable no-underscore-dangle */
import type { LatLngExpression } from 'leaflet';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useMapEvent } from 'react-leaflet';

import { isEqual, isUndefined } from '../../../utility';
import { StyledPopup } from '../StyledPopup';

import { TrafficIncidentsInfoBox } from './components';
import type { FeatureDetails } from './components/Xserver';
import { createXServerTileLayer } from './components/Xserver';
import type { CustomTileLayerProps, TileLayerMouseEvent, XServerTileLayerProps } from './models';
import { useStyles } from './styles';
import { getFeaturesPopupState } from './utils';

const XServerTileLayer: FC<XServerTileLayerProps> = (props) => {
    const { unitSystem, url, ...restProps } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const currentLayer = useRef<CustomTileLayerProps>();
    const popupInfoRef = useRef<{ identifier: string; items: FeatureDetails[]; position: LatLngExpression }>();

    const handleOnMouseMove = (e: TileLayerMouseEvent) => {
        const layer = currentLayer.current;

        if (!layer || !layer.findElement || !layer.getContainer()) {
            return;
        }

        const features = layer.findElement(e.originalEvent, layer.getContainer());
        const popupInfo = getFeaturesPopupState(features);

        if (popupInfo && !popupInfoRef.current && !isEqual(popupInfoRef.current, popupInfo)) {
            popupInfoRef.current = popupInfo;
            e.target._container.style.zIndex = 10;
            e.target._container.style.cursor = 'pointer';
            setIsOpen(true);
        }

        if (!popupInfo && popupInfoRef.current) {
            popupInfoRef.current = undefined;
            e.target._container.style.zIndex = 'initial';
            e.target._container.style.cursor = 'grab';
            setIsOpen(false);
        }
    };

    const map = useMapEvent('mousemove', handleOnMouseMove);

    useEffect(() => {
        currentLayer.current = createXServerTileLayer(url, restProps);
        currentLayer.current.addTo(map);

        return () => {
            currentLayer.current?.removeFrom(map);
            currentLayer.current = undefined;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    useEffect(() => {
        if (!isUndefined(restProps.zIndex)) {
            currentLayer.current?.setZIndex(restProps.zIndex);
        }
    }, [restProps.zIndex]);

    return isOpen && popupInfoRef.current ? (
        <StyledPopup className={classes.popup} position={popupInfoRef.current?.position}>
            <TrafficIncidentsInfoBox trafficIncidentsDetails={popupInfoRef.current?.items} unitSystem={unitSystem} />
        </StyledPopup>
    ) : null;
};
XServerTileLayer.displayName = 'XServerTileLayer';

export { XServerTileLayer };
