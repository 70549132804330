import { Tiles } from '@fv/components/Map';
import Close from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import type { Map } from 'leaflet';
import { divIcon } from 'leaflet';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { MapContainer, Marker, ZoomControl } from 'react-leaflet';

import type { MapViewport } from '~/common';
import { useReverseGeocodedAddress } from '~/common';
import { ConversationMiniMapPinIcon } from '~/components/Icons';
import { useTranslation } from '~/components/LanguageSelector';
import { MapProfile, MapViewMode, useMapLayers } from '~/components/MapSharedProps';
import { TitledIconButton } from '~/components/TitledIconButton';
import { isNil } from '~/libs/utility';
import type { Position } from '~/services/ApiClient';
import { renderToStaticThemedMarkup } from '~/services/React';

import { GeoPositionAddress } from '../../../../../GeoPositionAddress';

import { useStyles } from './styles';

export interface GeolocationPreviewProps {
    onClose: () => void;
    position: Position;
}

const GeolocationPreview: FC<GeolocationPreviewProps> = ({ onClose, position }) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const mapRef = useRef<Map | null>();
    const viewport: MapViewport = {
        center: [position.latitude, position.longitude],
        zoom: 12,
    };

    useEffect(() => {
        if (!mapRef.current || isNil(viewport.center)) {
            return;
        }

        if (!mapRef.current.getCenter().equals(viewport.center)) {
            mapRef.current.flyTo(viewport.center, mapRef.current.getZoom(), { animate: false });
        }
    }, [viewport.center]);
    const tiles = useMapLayers({ mapProfile: MapProfile.PTV_DEFAULT, mapViewMode: MapViewMode.MAP });

    const icon = divIcon({
        className: classes.positionIcon,
        html: renderToStaticThemedMarkup(theme, <ConversationMiniMapPinIcon fontSize="inherit" />),
        iconAnchor: [23, 50],
        iconSize: [46, 56],
    });

    const { address } = useReverseGeocodedAddress(position);

    return (
        <div className={classes.root} data-id="mini-map">
            <div className={classes.mapContainer}>
                <div className={classes.mapActionButtons}>
                    <TitledIconButton
                        className={classes.closeButton}
                        closeTooltipOnClick
                        onClick={onClose}
                        title={t('close')}
                    >
                        <Close fontSize="inherit" />
                    </TitledIconButton>
                </div>
                <MapContainer
                    {...viewport}
                    attributionControl={false}
                    className={classes.map}
                    doubleClickZoom={false}
                    dragging={false}
                    keyboard={false}
                    maxZoom={17}
                    minZoom={7}
                    ref={(instance) => {
                        mapRef.current = instance;
                    }}
                    scrollWheelZoom
                    tap={false}
                    touchZoom={false}
                    zoomControl={false}
                    zoomDelta={5}
                >
                    <ZoomControl
                        data-id="zoom-control"
                        position="bottomright"
                        zoomInTitle={t('zoom-in')}
                        zoomOutTitle={t('zoom-out')}
                    />
                    <Tiles data-id="tile-layer" tiles={tiles} />
                    <Marker icon={icon} position={[position.latitude, position.longitude]} />
                </MapContainer>
            </div>
            <div className={classes.addressContainer}>
                <GeoPositionAddress address={address} position={position} />
            </div>
        </div>
    );
};

GeolocationPreview.displayName = 'GeolocationPreview';
export default GeolocationPreview;
