import { AnimatedMarker, Map } from '@fv/components';
import { Typography } from '@mui/material';
import type { Map as LMap, LatLng, LatLngExpression } from 'leaflet';
import { latLngBounds } from 'leaflet';
import type { ComponentType, FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Marker, Polyline } from 'react-leaflet';

import type { Position } from '~/common';
import { MapProfile, MapViewMode, useMapLayers } from '~/components/MapSharedProps';
import { ReorderableSection } from '~/components/Sections';
import { subDates } from '~/libs/dates';
import { isEmpty } from '~/libs/utility';
import type { PositionStatus } from '~/services/ApiClient';

import noLocationImg from './assets/trucks-on-moon.png';
import { POLYLINE_PATH_OPTIONS } from './consts';
import type { GetAssetPositionArgs, MiniMapSectionInnerProps } from './models';

export const MiniMapSectionFactoryComponent = <T,>(
    getAssetIdentifier: (entry: T) => number,
    getAssetPositions: (args: GetAssetPositionArgs) => Promise<PositionStatus[]>,
    entryEquals: (entryA: T, entryB: T) => boolean
): ComponentType<MiniMapSectionInnerProps<T>> => {
    const MiniMapSectionComponent: FC<MiniMapSectionInnerProps<T>> = (props) => {
        const {
            AssetMarker,
            changeZoomLevel,
            classes,
            dragHandleElement,
            entry,
            getMarkerIcon,
            isCollapsed,
            lastLocation,
            t,
            toggleCollapsed,
            zoomLevel,
        } = props;

        const id = getAssetIdentifier(entry);
        const mapRef = useRef<LMap | null>();
        const [center, setCenter] = useState<LatLng | undefined>(undefined);
        const [markerPosition, setMarkerPosition] = useState<Position | undefined>(undefined);
        const [routeElement, setRouteElement] = useState<LatLngExpression[] | undefined>(undefined);

        const tiles = useMapLayers({ mapProfile: MapProfile.PTV_DEFAULT, mapViewMode: MapViewMode.MAP });

        const getItemKey = useCallback(
            (pos: Position) => {
                return `${getAssetIdentifier(entry)}-${pos.latitude}-${pos.longitude}`;
            },
            [entry]
        );

        const animatedMarkers = useMemo(() => {
            if (AssetMarker && markerPosition) {
                return (
                    <AnimatedMarker
                        data-id="marker-wrapper"
                        data-testid="marker-wrapper"
                        entry={entry}
                        entryEquals={entryEquals}
                        iconOptions={{ className: '', iconAnchor: [12, 12], iconSize: [24, 24] }}
                        key={getItemKey(markerPosition)}
                        position={[markerPosition.latitude, markerPosition.longitude]}
                        updateAnimationDuration={2000}
                    >
                        {({ animating, duration }) => (
                            <AssetMarker
                                animationDuration={duration}
                                entry={entry}
                                selected={false}
                                updateAnimationVisible={animating}
                            />
                        )}
                    </AnimatedMarker>
                );
            }

            return null;
        }, [AssetMarker, entry, getItemKey, markerPosition]);

        const unanimatedMarkers = useMemo(() => {
            const MarkerIcon = getMarkerIcon?.();

            if (MarkerIcon && markerPosition) {
                return (
                    <Marker
                        data-id="position-marker"
                        data-testid="position-marker"
                        icon={MarkerIcon}
                        key={getItemKey(markerPosition)}
                        position={[markerPosition.latitude, markerPosition.longitude]}
                    />
                );
            }

            return null;
        }, [getItemKey, getMarkerIcon, markerPosition]);

        useEffect(() => {
            const retrievePositions = async () => {
                const positionStatuses = await getAssetPositions({
                    endDate: new Date(),
                    id,
                    startDate: subDates(new Date(), { hours: 3 }),
                });

                if (!isEmpty(positionStatuses)) {
                    const tempPos = positionStatuses.map((p) => p.position);

                    setMarkerPosition(tempPos[tempPos.length - 1]);
                    if (tempPos.length >= 1) {
                        setRouteElement(tempPos.map((p) => [p.latitude, p.longitude]));
                        return;
                    }

                    setRouteElement(undefined);
                }

                if (isEmpty(positionStatuses)) {
                    if (!lastLocation) {
                        // needs to clear the reference because between position and not position assets, the reference point to an non-existent map
                        mapRef.current = undefined;
                    }

                    setMarkerPosition(lastLocation);
                }

                // need to clear the center to forcing the re-center on new position
                setCenter(undefined);
            };

            retrievePositions();
        }, [id, lastLocation]);

        useEffect(() => {
            if (markerPosition && !center) {
                const { latitude, longitude } = markerPosition;
                mapRef?.current?.flyTo([latitude, longitude], zoomLevel, { animate: false });
            }
        }, [markerPosition, mapRef, zoomLevel, center]);

        return (
            <ReorderableSection
                dataId="mini-map"
                dragHandleElement={dragHandleElement}
                headerOverContent
                isCollapsed={isCollapsed}
                title={t('mini-map')}
                toggleCollapsed={toggleCollapsed}
            >
                {!markerPosition ? (
                    <div className={classes.noLocationContainer}>
                        <img alt="" className={classes.noLocationImage} src={noLocationImg} />
                        <Typography data-id="no-location-title" variant="subtitle1">
                            {t('no-location-available')}
                        </Typography>
                        <Typography color="textSecondary" data-id="no-location-caption" variant="caption">
                            {t('cannot-present-location-on-minimap')}
                        </Typography>
                    </div>
                ) : (
                    <div className={classes.root}>
                        <div className={classes.content} data-id="mini-map">
                            <Map
                                center={center ?? [markerPosition.latitude, markerPosition.longitude]}
                                dragging
                                mapEvents={{
                                    dragend: () => {
                                        setCenter(mapRef.current?.getCenter());
                                    },
                                    zoom: changeZoomLevel,
                                }}
                                ref={(instance) => {
                                    mapRef.current = instance;
                                }}
                                relocate={{
                                    onClick: () => {
                                        setCenter(undefined);

                                        const latLng = { lat: markerPosition.latitude, lng: markerPosition.longitude };
                                        const bounds = latLngBounds([latLng]);
                                        mapRef.current?.fitBounds(bounds);
                                    },
                                }}
                                tiles={tiles}
                                zoom={zoomLevel}
                            >
                                {routeElement && (
                                    <Polyline pathOptions={POLYLINE_PATH_OPTIONS} positions={routeElement} />
                                )}
                                {animatedMarkers}
                                {unanimatedMarkers}
                            </Map>
                        </div>
                    </div>
                )}
            </ReorderableSection>
        );
    };

    return MiniMapSectionComponent;
};
