import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';
import type { VehicleDetailsPaneUserPreferences } from '~/data/monitoring';
import { SectionName } from '~/data/monitoring';

export const DETAILSPANE_VEHICLE_USERPREFERENCES_KEY = 'detailspane-vehicle-state';

export const defaultVehicleDetailsPaneUserPreferences: VehicleDetailsPaneUserPreferences = {
    collapsedSections: [],
    collapsedTemperatureCompartments: [],
    miniMapZoomLevel: 12,
    sectionOrder: [
        SectionName.MINIMAP,
        SectionName.LOCATION,
        SectionName.NAVIGATION,
        SectionName.STAFF,
        SectionName.TRAILERS,
        SectionName.EBS,
        SectionName.TEMPERATURES,
        SectionName.FUELLEVEL,
        SectionName.LATESTMESSAGES,
        SectionName.VEHICLEDETAILS,
    ],
};

export const processResponse = (
    preferences: Partial<VehicleDetailsPaneUserPreferences>
): VehicleDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultVehicleDetailsPaneUserPreferences);
};

export const requiredVehicleDetailsPaneUserPreferences = {
    defaultState: defaultVehicleDetailsPaneUserPreferences,
    key: DETAILSPANE_VEHICLE_USERPREFERENCES_KEY,
    processResponse,
};
