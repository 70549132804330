import { LocationSection, MiniMapSectionFactory, NavigationSection } from '~/common';
import { VehicleMarker } from '~/components/Map';
import type { SectionDefinition } from '~/components/Sections';
import type { MonitoringVehicleEntry } from '~/data/monitoring';
import { MonitoringPerspective, SectionName } from '~/data/monitoring';
import { monitoringVehicleEntryEquals } from '~/scenes/Monitoring/utils';
import type { Securables } from '~/services/ApiClient';
import { ApiClient, AssetType } from '~/services/ApiClient';
import { memoizeOne } from '~/services/Memoize';

import { AssetHistorySubpageFactory } from '../AssetHistorySubpage';
import { LocationSectionActionButtons } from '../LocationSectionActionButtons';

import { ConnectedTrailerTemperaturesSection } from './components/ConnectedTrailerTemperaturesSection';
import { DriverInfoSection } from './components/DriverInfoSection';
import { EbsSectionComponent } from './components/EbsSection';
import { FuelLevelSection } from './components/FuelLevelSection';
import { LatestMessagesSection } from './components/LatestMessagesSection';
import { TrailersSection } from './components/TrailersSection';
import { VehicleDetailsSection } from './components/VehicleDetailsSection';
import type { SectionProps } from './model';

const MiniMapSectionComponent = MiniMapSectionFactory<MonitoringVehicleEntry>(
    (entry: MonitoringVehicleEntry) => entry.vehicle.id,
    async ({ endDate, id, startDate }) => {
        const { items } = await ApiClient.getVehiclePositions(id, startDate, endDate);
        return items;
    },
    monitoringVehicleEntryEquals
);

export const VehicleHistorySubpage = AssetHistorySubpageFactory(AssetType.Vehicle);

export const getSections = (props: SectionProps): SectionDefinition[] => {
    const {
        assetGroups,
        changeCollapsedTemperatureCompartments,
        changeMiniMapZoomLevel,
        collapsedSections,
        collapsedTemperatureCompartments,
        depots,
        entry,
        handleLocate,
        miniMapZoomLevel,
        toggleCollapsedState,
        viewMode,
    } = props;
    const locationSectionActionButtons = (
        <LocationSectionActionButtons
            assetId={entry.vehicle.id}
            onLocate={handleLocate}
            perspective={MonitoringPerspective.VEHICLE}
            position={entry.vehicleStatus?.position}
            showHistoryButton
            viewMode={viewMode}
        />
    );
    return [
        {
            content: (dragHandleElement: JSX.Element) => (
                <LocationSection
                    actionButtons={locationSectionActionButtons}
                    address={entry.vehicleStatus?.address}
                    courseOverGround={entry.vehicleStatus?.courseOverGround}
                    datetime={entry.vehicleStatus?.datetime}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.LOCATION)}
                    odometer={entry.vehicleStatus?.odometer}
                    position={entry.vehicleStatus?.position}
                    speedOverGround={entry.vehicleStatus?.speedOverGround}
                    toggleCollapsed={toggleCollapsedState(SectionName.LOCATION)}
                />
            ),
            name: SectionName.LOCATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <NavigationSection
                    destination={entry.routeStatus?.destination}
                    dragHandleElement={dragHandleElement}
                    driverHoursEta={entry.routeStatus?.driverHoursEta}
                    isCollapsed={collapsedSections.includes(SectionName.NAVIGATION)}
                    lastUpdateDatetime={entry.routeStatus?.dateTime}
                    navigationEta={entry.routeStatus?.eta}
                    routeStatus={entry.routeStatus?.status}
                    toggleCollapsed={toggleCollapsedState(SectionName.NAVIGATION)}
                />
            ),
            name: SectionName.NAVIGATION,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <DriverInfoSection
                    assetId={entry.vehicle.id}
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.STAFF)}
                    perspective={MonitoringPerspective.VEHICLE}
                    toggleCollapsed={toggleCollapsedState(SectionName.STAFF)}
                    viewMode={viewMode}
                />
            ),
            name: SectionName.STAFF,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <VehicleDetailsSection
                    assetGroups={assetGroups}
                    depots={depots}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.VEHICLEDETAILS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.VEHICLEDETAILS)}
                    vehicle={entry.vehicle}
                />
            ),
            name: SectionName.VEHICLEDETAILS,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <MiniMapSectionComponent
                    AssetMarker={VehicleMarker}
                    changeZoomLevel={changeMiniMapZoomLevel}
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.MINIMAP)}
                    lastLocation={entry.vehicleStatus?.position}
                    toggleCollapsed={toggleCollapsedState(SectionName.MINIMAP)}
                    zoomLevel={miniMapZoomLevel}
                />
            ),
            name: SectionName.MINIMAP,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <LatestMessagesSection
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.LATESTMESSAGES)}
                    toggleCollapsed={toggleCollapsedState(SectionName.LATESTMESSAGES)}
                />
            ),
            name: SectionName.LATESTMESSAGES,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <TrailersSection
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.TRAILERS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.TRAILERS)}
                />
            ),
            name: SectionName.TRAILERS,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <ConnectedTrailerTemperaturesSection
                    changeCollapsedCompartments={changeCollapsedTemperatureCompartments}
                    collapsedCompartments={collapsedTemperatureCompartments}
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.TEMPERATURES)}
                    toggleCollapsed={toggleCollapsedState(SectionName.TEMPERATURES)}
                    trailerConnection={entry.trailerConnection}
                />
            ),
            name: SectionName.TEMPERATURES,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <FuelLevelSection
                    dragHandleElement={dragHandleElement}
                    isCollapsed={collapsedSections.includes(SectionName.FUELLEVEL)}
                    toggleCollapsed={toggleCollapsedState(SectionName.FUELLEVEL)}
                    vehicleId={entry.vehicle.id}
                />
            ),
            name: SectionName.FUELLEVEL,
        },
        {
            content: (dragHandleElement: JSX.Element) => (
                <EbsSectionComponent
                    dragHandleElement={dragHandleElement}
                    entry={entry}
                    isCollapsed={collapsedSections.includes(SectionName.EBS)}
                    toggleCollapsed={toggleCollapsedState(SectionName.EBS)}
                />
            ),
            name: SectionName.EBS,
        },
    ];
};

export const getUnauthorizedSections = (securables: Securables): SectionName[] => {
    const result = [];

    if (!securables.monitoring?.trailers) {
        result.push(SectionName.TRAILERS);
        result.push(SectionName.TEMPERATURES);
    }

    if (!securables.monitoring.map) {
        result.push(SectionName.MINIMAP);
    }

    if (!securables.monitoring.driverStatus) {
        result.push(SectionName.STAFF);
    }

    return result;
};

export const getSectionsMemoized = memoizeOne(getSections);
export const getUnauthorizedSectionsMemoized = memoizeOne(getUnauthorizedSections);
