import type { SceneDetailsPaneUserPreferences } from '~/components/SceneDetailsPane';
import { processSceneDetailsPanePreferencesResponse } from '~/components/SceneDetailsPane';

import { SectionName } from './constants';

export const DETAILSPANE_TRAILER_USERPREFERENCES_KEY = 'detailspane-trailer-state';

export interface TrailerDetailsPaneUserPreferences extends SceneDetailsPaneUserPreferences<SectionName> {
    collapsedCompartments: number[];
    miniMapZoomLevel: number;
}

export const defaultTrailerDetailsPaneUserPreferences: TrailerDetailsPaneUserPreferences = {
    collapsedCompartments: [],
    collapsedSections: [],
    miniMapZoomLevel: 12,
    sectionOrder: [
        SectionName.MINIMAP,
        SectionName.LOCATION,
        SectionName.VEHICLE,
        SectionName.TEMPERATURES,
        SectionName.DOORS,
        SectionName.REEFER,
        SectionName.EBS,
    ],
};

export const processResponse = (
    preferences: Partial<TrailerDetailsPaneUserPreferences>
): TrailerDetailsPaneUserPreferences => {
    return processSceneDetailsPanePreferencesResponse(preferences, defaultTrailerDetailsPaneUserPreferences);
};

export const requiredTrailerDetailsPaneUserPreferences = {
    defaultState: defaultTrailerDetailsPaneUserPreferences,
    key: DETAILSPANE_TRAILER_USERPREFERENCES_KEY,
    processResponse,
};
