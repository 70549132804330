import { getConfig } from '~/services/Config';

export enum PtvLayer {
    BACKGROUND = 'background',
    LABELS = 'labels',
    TRAFFICINCIDENTS = 'PTV_TrafficIncidents',
    TRANSPORT = 'transport',
    TRUCKATTRIBUTES = 'PTV_TruckAttributes',
}

export enum PtvMapProfile {
    DEFAULT = 'gravelpit',
    SILICA = 'silkysand',
}

export interface PtvUrlOptions {
    isJson?: boolean;
    language?: string;
    layers: NonNullable<PtvLayer[]>;
    profile?: PtvMapProfile;
    timestamp?: number;
}
export const getPtvLayerUrl = ({ isJson, language, layers, profile, timestamp }: PtvUrlOptions): string => {
    let baseUrl = getConfig()
        .mapViewsUrls.ptv.replace('{layers}', layers.join(','))
        .replace('{storedProfile}', profile ?? PtvMapProfile.DEFAULT);

    if (timestamp) {
        const referenceTime = encodeURIComponent(new Date(timestamp).toISOString());
        baseUrl += `&timeConsideration=SNAPSHOT&referenceTime=${referenceTime}&showOnlyRelevantByTime=false`;
    }

    if (isJson) {
        baseUrl += `&contentType=JSON`;
    }

    if (language) {
        baseUrl += `&userLanguage=${language}`;
    }

    return baseUrl;
};
