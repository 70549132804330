import accidentIcon from './assets/accident.png';
import blockedRoadIcon from './assets/blocked_road.png';
import blockedRoadTruckIcon from './assets/blocked_road_truck.png';
import dangerIcon from './assets/danger.png';
import defaultIcon from './assets/default.png';
import delayIcon from './assets/delay.png';
import delayTruckIcon from './assets/delay_truck.png';
import fogIcon from './assets/fog.png';
import iceIcon from './assets/ice.png';
import junctionClosedIcon from './assets/junction_closed.png';
import laneClosedIcon from './assets/lane_closed.png';
import obstructionIcon from './assets/obstruction.png';
import railroadcrossingIcon from './assets/railroadcrossing.png';
import rainIcon from './assets/rain.png';
import roadworkIcon from './assets/roadwork.png';
import snowIcon from './assets/snow.png';
import trafficJamIcon from './assets/traffic_jam.png';
import trafficlightsDefectIcon from './assets/trafficlights_defect.png';
import vehicleFireIcon from './assets/vehicle_fire.png';
import weatherIcon from './assets/weather.png';
import weatherforecastIcon from './assets/weatherforecast.png';
import windIcon from './assets/wind.png';

export const incidentData = {
    10: { icon: trafficJamIcon, translationKey: 'traffic-jam' },
    101: { icon: iceIcon, translationKey: 'ice' },
    102: { icon: snowIcon, translationKey: 'snow' },
    103: { icon: fogIcon, translationKey: 'fog' },
    104: { icon: rainIcon, translationKey: 'rain' },
    105: { icon: windIcon, translationKey: 'wind' },
    106: { icon: weatherIcon, translationKey: 'weather' },
    110: { icon: roadworkIcon, translationKey: 'roadwork' },
    141: { icon: snowIcon, translationKey: 'snow' },
    142: { icon: rainIcon, translationKey: 'rain' },
    144: { icon: weatherforecastIcon, translationKey: 'weather-forecast' },
    150: { icon: roadworkIcon, translationKey: 'roadwork' },
    20: { icon: trafficJamIcon, translationKey: 'traffic-jam' },
    30: { icon: trafficJamIcon, translationKey: 'traffic-jam' },
    41: { icon: delayIcon, translationKey: 'delay' },
    42: { icon: delayTruckIcon, translationKey: 'delay-truck' },
    51: { icon: blockedRoadIcon, translationKey: 'blocked-road' },
    52: { icon: junctionClosedIcon, translationKey: 'junction-closed' },
    53: { icon: blockedRoadTruckIcon, translationKey: 'blocked-road-truck' },
    61: { icon: laneClosedIcon, translationKey: 'lane-closed' },
    62: { icon: obstructionIcon, translationKey: 'obstruction' },
    71: { icon: vehicleFireIcon, translationKey: 'vehicle-fire' },
    72: { icon: accidentIcon, translationKey: 'accident' },
    80: { icon: dangerIcon, translationKey: 'danger' },
    91: { icon: railroadcrossingIcon, translationKey: 'railroad-crossing' },
    92: { icon: trafficlightsDefectIcon, translationKey: 'trafficlights-defect' },
};

export const unknownIncidentIcon = defaultIcon;

export enum RawTrafficIncidentDetailsAttributesKeys {
    ABSOLUTE_SPEED = 'absoluteSpeed',
    CATEGORY = 'category',
    DELAY = 'delay',
    INCIDENT_ID = 'incidentId',
    LENGTH = 'length',
    MESSAGE_LANGUAGE = 'message.language',
    MESSAGE_TEXT = 'message.text',
    TIME_DOMAIN = 'timeDomain',
}

export interface TrafficIncidentAttributes {
    absoluteSpeed?: number;
    category: number;
    delay?: number;
    incidentId?: string;
    length?: number;
    messageLanguage?: string;
    messageText?: string;
    timeDomain?: Date;
}
