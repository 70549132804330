import type { TilesTypes } from '@fv/components';

import type { DisplayUserPreferencesUnitSystem, MapLayersSecurables } from '~/services/ApiClient';
import { MapMode } from '~/services/MapGatewayClient';

import { getMapGLLayerProps, getMapLayerProps } from './layer';
import { MapLayer } from './layers.constants';
import type { PtvMapProfile } from './layers.ptv';
import { PtvLayer, getPtvLayerUrl } from './layers.ptv';
import type { MapProfile } from './mapProfile';
import { MapViewMode } from './mapViewMode';

export const getDefaultLayers = (
    mapProfile: MapProfile,
    unitSystem: DisplayUserPreferencesUnitSystem
): TilesTypes[] => [
    getMapLayerProps({
        layer: MapLayer.MAP,
        unitSystem,
        url: getPtvLayerUrl({
            layers: [PtvLayer.BACKGROUND, PtvLayer.TRANSPORT, PtvLayer.LABELS],
            profile: mapProfile as unknown as PtvMapProfile,
        }),
    }),
];

type GetDefaultMaplibreGLLayersArgs = {
    includeTrafficIncidents: boolean;
    includeTruckAttributes: boolean;
    language?: string;
    unitSystem: DisplayUserPreferencesUnitSystem;
};

export const getDefaultMaplibreGLLayers = ({
    includeTrafficIncidents,
    includeTruckAttributes,
    language,
    unitSystem,
}: GetDefaultMaplibreGLLayersArgs): TilesTypes[] => [
    getMapGLLayerProps({
        includeTrafficIncidents,
        includeTruckAttributes,
        language,
        mapMode: MapMode.Road,
        unitSystem,
    }),
];

interface GetBaseMapLayersPropsArgs {
    language?: string;
    mapLayersSecurables: MapLayersSecurables;
    mapProfile: MapProfile;
    mapViewMode: MapViewMode;
    trafficIncidentsOverlayEnabled?: boolean;
    truckAttributesOverlayEnabled?: boolean;
    unitSystem: DisplayUserPreferencesUnitSystem;
}

export const getBaseMapLayersProps = (args: GetBaseMapLayersPropsArgs): TilesTypes[] => {
    const {
        language,
        mapLayersSecurables,
        mapViewMode,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
        unitSystem,
    } = args;

    const includeTruckAttributes = Boolean(truckAttributesOverlayEnabled && mapLayersSecurables.truckAttributesAllowed);
    const includeTrafficIncidents = Boolean(
        trafficIncidentsOverlayEnabled && mapLayersSecurables.trafficIncidentsAllowed
    );

    if (!mapLayersSecurables?.satelliteAllowed) {
        return getDefaultMaplibreGLLayers({
            includeTrafficIncidents,
            includeTruckAttributes,
            language,
            unitSystem,
        });
    }

    switch (mapViewMode) {
        case MapViewMode.HYBRID:
            return [
                getMapGLLayerProps({
                    includeTrafficIncidents,
                    includeTruckAttributes,
                    mapMode: MapMode.Hybrid,
                    unitSystem,
                }),
            ];

        case MapViewMode.SATELLITE:
            return [
                getMapGLLayerProps({
                    includeTrafficIncidents,
                    includeTruckAttributes,
                    mapMode: MapMode.Satellite,
                    unitSystem,
                }),
            ];

        default:
            return getDefaultMaplibreGLLayers({
                includeTrafficIncidents,
                includeTruckAttributes,
                language,
                unitSystem,
            });
    }
};
