import type { TilesTypes } from '@fv/components';
import type { Dispatch } from 'redux';

import { updateUserPreferencesAction } from '~/data/userpreferences';
import { memoizeOne } from '~/services/Memoize';

import type { MapLayersSecurables, Securables } from '../../services/ApiClient/generated';
import type { DisplayUserPreferences } from '../DisplayPreferences';
import type { LanguageStoreState } from '../LanguageSelector/components/LanguageSelector';

import { getMapLayersProps } from './layers';
import type { MapProfile } from './mapProfile';
import type { MapViewMode } from './mapViewMode';
import type { MapUserPreferences } from './preferences';
import { MAP_USERPREFERENCES_KEY } from './preferences';

export interface MapStateProps {
    followSelectedAsset: boolean;
    mapLayersProps: TilesTypes[];
    mapLayersSecurables: MapLayersSecurables;
    mapViewMode: MapViewMode;
    markerClusterRadius: number;
    trafficIncidentsOverlayEnabled: boolean;
    truckAttributesOverlayEnabled: boolean;
}

export interface MapDispatchProps {
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    changeMapViewMode: (mapViewMode: MapViewMode) => void;
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void;
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void;
}

export interface MapReduxProps extends MapStateProps {
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => void;
    changeMapViewMode: (mapViewMode: MapViewMode) => void;
    toggleTrafficIncidentsOverlayEnabled: () => void;
    toggleTruckAttributesOverlayEnabled: () => void;
}

export const mapStateToProps = (
    timestamp: number,
    {
        followSelectedAsset,
        markerClusterRadius,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
        viewMode: mapViewMode,
    }: MapUserPreferences,
    securables: Securables,
    languageState: LanguageStoreState,
    displayPreferences: DisplayUserPreferences,
    mapProfile: MapProfile
): MapStateProps => {
    return {
        followSelectedAsset,
        mapLayersProps: getMapLayersProps({
            language: languageState.currentLanguage && languageState.currentLanguage.code,
            mapLayersSecurables: securables.mapLayers,
            mapProfile,
            mapViewMode,
            timestamp,
            trafficIncidentsOverlayEnabled,
            truckAttributesOverlayEnabled,
            unitSystem: displayPreferences.unitSystem,
        }),
        mapLayersSecurables: securables.mapLayers,
        mapViewMode,
        markerClusterRadius,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): MapDispatchProps => ({
    changeFollowSelectedAsset: (followSelectedAsset: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { followSelectedAsset }));
    },
    changeMapViewMode: (viewMode: MapViewMode) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { viewMode }));
    },
    changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { trafficIncidentsOverlayEnabled }));
    },
    changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => {
        dispatch(updateUserPreferencesAction(MAP_USERPREFERENCES_KEY, { truckAttributesOverlayEnabled }));
    },
});

const toggleTruckAttributesOverlayEnabledMemoized = memoizeOne(
    (
        truckAttributesOverlayEnabled: boolean | undefined,
        changeTruckAttributesOverlayEnabled: (truckAttributesOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTruckAttributesOverlayEnabled(!truckAttributesOverlayEnabled);
        }
);
const toggleTrafficIncidentsOverlayEnabledMemoized = memoizeOne(
    (
        trafficIncidentsOverlayEnabled: boolean | undefined,
        changeTrafficIncidentsOverlayEnabled: (trafficIncidentsOverlayEnabled: boolean) => void
    ) =>
        () => {
            changeTrafficIncidentsOverlayEnabled(!trafficIncidentsOverlayEnabled);
        }
);

export const mergeProps = (
    stateProps: MapStateProps,
    {
        changeTrafficIncidentsOverlayEnabled,
        changeTruckAttributesOverlayEnabled,
        ...restDispatchProps
    }: MapDispatchProps,
    ownProps: object
): MapReduxProps => ({
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    toggleTrafficIncidentsOverlayEnabled: toggleTrafficIncidentsOverlayEnabledMemoized(
        stateProps.trafficIncidentsOverlayEnabled,
        changeTrafficIncidentsOverlayEnabled
    ),
    toggleTruckAttributesOverlayEnabled: toggleTruckAttributesOverlayEnabledMemoized(
        stateProps.truckAttributesOverlayEnabled,
        changeTruckAttributesOverlayEnabled
    ),
});
