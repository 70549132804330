import type { TilesTypes } from '@fv/components';

import { getBaseMapLayersProps } from './layers.base';
import type { GetMapLayersPropsArgs } from './models';

export const getMapLayersProps = (args: GetMapLayersPropsArgs): TilesTypes[] => {
    const {
        language,
        mapLayersSecurables,
        mapProfile,
        mapViewMode,
        trafficIncidentsOverlayEnabled,
        truckAttributesOverlayEnabled,
        unitSystem,
    } = args;
    return [
        ...getBaseMapLayersProps({
            language,
            mapLayersSecurables,
            mapProfile,
            mapViewMode,
            trafficIncidentsOverlayEnabled,
            truckAttributesOverlayEnabled,
            unitSystem,
        }),
    ];
};
