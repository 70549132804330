import type { UnitSystem } from '@fv/converters';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material/Typography';
import type { WithStyles } from '@mui/styles';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import { formatDistance, formatSpeed } from '../../../../../formatters';
import { DelayIcon, DistanceIcon, SpeedometerIcon } from '../../../../../Icons';
import { isNil, isUndefined } from '../../../../../utility';
import type { FeatureDetails } from '../Xserver';

import { getAttributesFromIncidentDetails } from './getAttributesFromIncidentDetails';
import type { TrafficIncidentAttributes } from './incidentData';
import { incidentData, unknownIncidentIcon } from './incidentData';
import type { TrafficIncidentsInfoBoxClassKey } from './styles';

export interface TrafficIncidentsInfoBoxProps {
    trafficIncidentsDetails?: FeatureDetails[];
    unitSystem: UnitSystem;
}

export interface TrafficIncidentsInfoBoxInnerProps
    extends TrafficIncidentsInfoBoxProps,
        WithStyles<TrafficIncidentsInfoBoxClassKey> {}

export const TrafficIncidentsInfoBoxComponent: React.FC<TrafficIncidentsInfoBoxInnerProps> = ({
    classes,
    trafficIncidentsDetails,
    unitSystem,
}) => {
    const { t } = useTranslation();

    if (isNil(trafficIncidentsDetails)) {
        return null;
    }

    const items = trafficIncidentsDetails.map((featureDetails: FeatureDetails) => {
        const attributes: TrafficIncidentAttributes = getAttributesFromIncidentDetails(featureDetails);
        const currentIncidentData = incidentData[attributes.category];

        const delayElement = !isUndefined(attributes.delay) && (
            <React.Fragment key="delay">
                <DelayIcon className={classes.infoIcon} />
                <span data-id="delay">{moment.duration(attributes.delay, 's').format('m __')}</span>
            </React.Fragment>
        );

        const lengthElement = !isUndefined(attributes.length) && (
            <React.Fragment key="distance">
                <DistanceIcon className={classes.infoIcon} />
                <span data-id="distance">{formatDistance({ precision: 1, unitSystem, value: attributes.length })}</span>
            </React.Fragment>
        );

        const speedElement = !isUndefined(attributes.absoluteSpeed) && (
            <React.Fragment key="absolute-speed">
                <SpeedometerIcon className={classes.infoIcon} />
                <span data-id="absoluteSpeed">{formatSpeed({ unitSystem, value: attributes.absoluteSpeed })}</span>
            </React.Fragment>
        );

        const infoElementsArray: React.ReactNode[] = [delayElement, lengthElement, speedElement].filter(Boolean);
        const infoElementsWithDelimiters = infoElementsArray.reduce((prev, curr, index) => {
            const key = `delimiter-${index}`;
            const delimiter = (
                <div className={classes.delimiter} key={key}>
                    •
                </div>
            );

            return [prev, delimiter, curr];
        });

        const incidentInfoElement = (
            <Typography
                className={classes.incidentInfo}
                color="textSecondary"
                data-id="incident-info-element"
                gutterBottom
                variant="caption"
            >
                {infoElementsWithDelimiters}
            </Typography>
        );

        const ageElement = attributes.timeDomain && (
            <Typography className={classes.age} color="textSecondary" data-id="age" variant="caption">
                <Moment fromNow>{attributes.timeDomain.toUTCString()}</Moment>
            </Typography>
        );

        const incidentTitle = currentIncidentData
            ? t(`traffic-incident-category-${currentIncidentData.translationKey}`)
            : t('traffic-incident');

        const titleAndTimestampElement = (
            <>
                <Typography data-id="title" variant="subtitle2">
                    {incidentTitle}
                </Typography>
                {ageElement}
            </>
        );

        const titleAndTimestampTypographyProps: Partial<TypographyProps> = {
            className: classes.titleAndTimestamp,
            variant: 'subtitle2',
        };

        return (
            <ListItem className={classes.listItem} key={featureDetails.id}>
                <div className={classes.firstRow}>
                    <img
                        alt={incidentTitle}
                        className={classes.icon}
                        height={32}
                        src={currentIncidentData ? currentIncidentData.icon : unknownIncidentIcon}
                        width={32}
                    />

                    <ListItemText
                        className={classes.listItemText}
                        primary={titleAndTimestampElement}
                        primaryTypographyProps={titleAndTimestampTypographyProps}
                        secondary={incidentInfoElement}
                        secondaryTypographyProps={{ variant: 'caption' }}
                    />
                </div>

                <Typography className={classes.description} component="div" variant="body2">
                    {attributes.messageText}
                </Typography>
            </ListItem>
        );
    });

    return <List className={classes.root}>{items}</List>;
};
