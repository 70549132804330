import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useDisplayPreferences } from '~/common/hooks';
import { SettingsKey, settingDataSelector } from '~/components/EnsureSettings';
import { useLanguageCode } from '~/components/LanguageSelector';

import { getBaseMapLayersProps } from '../layers.base';
import type { MapProfile } from '../mapProfile';
import type { MapViewMode } from '../mapViewMode';

interface UseMapLayersArgs {
    mapProfile: MapProfile;
    mapViewMode: MapViewMode;
}

const useMapLayers = (args: UseMapLayersArgs) => {
    const { mapProfile, mapViewMode } = args;
    const { unitSystem } = useDisplayPreferences();
    const { mapLayers } = useSelector(settingDataSelector(SettingsKey.SECURABLES));
    const language = useLanguageCode();

    return useMemo(
        () =>
            getBaseMapLayersProps({
                language,
                mapLayersSecurables: mapLayers,
                mapProfile,
                mapViewMode,
                unitSystem,
            }),
        [mapViewMode, mapProfile, language, unitSystem, mapLayers]
    );
};

export { useMapLayers };
