import { useEffect, useState } from 'react';

import type { MapMode } from '../../services/MapGatewayClient';
import { getMapGatewayClient } from '../../services/MapGatewayClient';

interface UseMaplibreStyleProps {
    includeTrafficIncidents?: boolean;
    includeTruckAttributes?: boolean;
    language?: string;
    mapMode: MapMode;
}

const useMaplibreStyle = (props: UseMaplibreStyleProps) => {
    const [style, setStyle] = useState<undefined | void>();
    const { includeTrafficIncidents, includeTruckAttributes, language, mapMode } = props;
    const MapGatewayClient = getMapGatewayClient();

    useEffect(() => {
        MapGatewayClient.getMaplibreStyle(mapMode, includeTruckAttributes, includeTrafficIncidents, language).then(
            (res) => {
                const fileReader = new FileReader();

                fileReader.addEventListener(
                    'load',
                    () => {
                        if (typeof fileReader.result === 'string') {
                            const serializedStyleResponse = JSON.parse(fileReader.result);
                            setStyle(serializedStyleResponse);
                        }
                    },
                    false
                );

                fileReader.readAsText(res.data);
            }
        );
    }, [MapGatewayClient, includeTrafficIncidents, includeTruckAttributes, mapMode, language]);

    return style;
};

export { useMaplibreStyle };
export type { UseMaplibreStyleProps };
