import { MapViewMode } from './mapViewMode';

export const MAP_USERPREFERENCES_KEY = 'map-state';

export interface MapUserPreferences {
    followSelectedAsset: boolean;
    markerClusterRadius: number;
    trafficIncidentsOverlayEnabled: boolean;
    truckAttributesOverlayEnabled: boolean;
    viewMode: MapViewMode;
}

export const defaultMapUserPreferences: MapUserPreferences = {
    followSelectedAsset: true,
    markerClusterRadius: 65,
    trafficIncidentsOverlayEnabled: false,
    truckAttributesOverlayEnabled: false,
    viewMode: MapViewMode.MAP,
};

export const requiredMapUserPreferences = {
    defaultState: defaultMapUserPreferences,
    key: MAP_USERPREFERENCES_KEY,
};
